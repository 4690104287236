import { EmailMessagePreview } from 'src/types'

export function sortFlaggedMessages(messages: EmailMessagePreview[]): EmailMessagePreview[] {
  const sortedMessages = messages.sort((a, b) => {
    // Check if the dueDateTime properties are defined
    const aDueDateTime = a.flag.dueDateTime?.dateTime
    const bDueDateTime = b.flag.dueDateTime?.dateTime

    if (aDueDateTime === undefined) return 1
    if (bDueDateTime === undefined) return -1

    if (aDueDateTime > bDueDateTime) return 1
    if (aDueDateTime < bDueDateTime) return -1
    return 0
  })
  return sortedMessages
}

export function getSortQuery(sortDirection: 'asc' | 'desc' = 'desc', filterUnread?: boolean, orderByLastModified?: boolean) {
  let query = ''
  if (orderByLastModified) return '&$orderby=lastModifiedDateTime desc'
  if (filterUnread) query += '&$filter=isRead eq false'
  if (sortDirection !== 'desc') query += '&$orderby=sentDateTime asc'
  return query
}

// Email Attachments
function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export async function getFileContentAsBase64(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (event) {
      const arrayBuffer = event.target?.result as ArrayBuffer
      const base64 = arrayBufferToBase64(arrayBuffer)
      resolve(base64)
    }
    reader.onerror = function (error) { reject(error) }
    reader.readAsArrayBuffer(file)
  })
}

export function sortMessagesBySentDateDescending(messages: EmailMessagePreview[]) {
  const sortedMessages = messages.sort((a, b) => {
    // Check if the dueDateTime properties are defined
    const dateA = a.sentDateTime
    const dateB = b.sentDateTime

    if (dateA === undefined) return -1
    if (dateB === undefined) return 1

    if (dateA > dateB) return -1
    if (dateA < dateB) return 1
    return 0
  })
  return sortedMessages
}

export function sortMessagesBySentDateAscending(messages: EmailMessagePreview[]) {
  const sortedMessages = messages.sort((a, b) => {
    // Check if the dueDateTime properties are defined
    const dateA = a.sentDateTime
    const dateB = b.sentDateTime

    if (dateA === undefined) return 1
    if (dateB === undefined) return -1

    if (dateA > dateB) return 1
    if (dateA < dateB) return -1
    return 0
  })
  return sortedMessages
}
