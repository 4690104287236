import { route } from 'quasar/wrappers'
import { Loading } from 'quasar'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteLocationNormalized
} from 'vue-router'

import routes from './routes'

// Define the function to update the document title
function updateDocumentTitle(to: RouteLocationNormalized) {
  const appName = 'Levur' // Your app's default name
  const instanceTitle = import.meta.env.VITE_INSTANCE_TITLE ? ' (' + import.meta.env.VITE_INSTANCE_TITLE + ')' : ''
  const pageTitle = to.meta.title + ' | Levur ' + instanceTitle
  document.title = `${pageTitle} | ${appName}`
}

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })

  // Add the global navigation guard
  Router.beforeEach((to, from, next) => {
    Loading.show() // show the loading indicator
    updateDocumentTitle(to)
    next()
  })

  Router.afterEach(() => {
    Loading.hide() // hide the loading indicator
  })

  return Router
})
