export { type AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser'
export const scopes = ['Mail.Send', 'Mail.ReadWrite', 'MailboxSettings.ReadWrite', 'Calendars.ReadWrite.Shared', 'Calendars.ReadWrite', 'Group.Read.All']

export * from './enums' // Enums
export * from './common' // Common
export * from './attachments' // Attachments
export * from './events' // Events
export * from './messages' // Messages
export * from './flags' // Flags
export * from './folders' // Folders
