/** Converts a string (YYYY-MM-DD) to a Date */
export function stringToDate(date: string): Date {
  return new Date(date + 'T12:00:00')
}

/** Converts a Date to a YYYY-MM-DD string */
export function dateToString(date: Date): string {
  date.setHours(12, 0, 0, 0)
  return date.toISOString().split('T')[0]
}

/** Get todays date as YYYY-MM-DD string */
export function currentDateAsString(): string {
  return dateToString(new Date())
}

export function dateIsEqualOrAfter(date: Date, afterDate: Date): boolean {
  return date.setHours(0, 0, 0, 0) >= afterDate.setHours(0, 0, 0, 0)
}

export function dateIsEqualOrBefore(date: Date, beforeDate: Date): boolean {
  return date.setHours(0, 0, 0, 0) <= beforeDate.setHours(0, 0, 0, 0)
}

export function yearFromDate(date: Date): number {
  date.setHours(12, 0, 0, 0)
  return date.getFullYear()
}

export function yearFromDateString(date: string): number {
  return yearFromDate(stringToDate(date))
}

export function currentYear(): number {
  return yearFromDate(new Date())
}

// Function to get the start and end of a date in the user's local time zone and convert to UTC
export function getUTCRangeForDate(localDate: string) {
  const localDateStart = new Date(`${localDate}T00:00:00`)
  const localDateEnd = new Date(`${localDate}T23:59:59.999`)
  const startUTC = new Date(localDateStart.getTime()).toISOString()
  const endUTC = new Date(localDateEnd.getTime()).toISOString()

  return { startUTC, endUTC }
}
