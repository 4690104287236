import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import type { Setting } from 'src/types'
import * as api from 'src/api'

export const useSettingsStore = defineStore('settings', () => {
  // ----------------------------------------
  // Settings
  // ----------------------------------------
  const settings: Ref<Setting[]> = ref([])

  // Week Starts On (Timesheets)
  const weekStartsOn = computed<number>(() => {
    const index = settings.value.findIndex(v => v.key === 'weekStartsOn')
    if (index !== -1) {
      if (settings.value[index].number_value !== null && typeof settings.value[index].number_value === 'number') {
        return settings.value[index].number_value as number
      } else return 0
    } else return 0
  })

  // Time Entry Interval (Timesheets)
  const timeEntryInterval = computed<number>(() => {
    const index = settings.value.findIndex(v => v.key === 'timeEntryInterval')
    if (index !== -1) {
      if (settings.value[index].number_value !== null && typeof settings.value[index].number_value === 'number') {
        return settings.value[index].number_value as number
      } else return 0
    } else return 0
  })

  // Opportunity Estimate Approval Due Date Offset (Opportunities)
  const oppEstApprovalDueDateOffset = computed<number>(() => {
    const index = settings.value.findIndex(v => v.key === 'oppEstApprovalDueDateOffset')
    if (index !== -1) {
      if (settings.value[index].number_value !== null && typeof settings.value[index].number_value === 'number') {
        return settings.value[index].number_value as number
      } else return 0
    } else return 0
  })

  // Opportunity Estimate Master Approval Due Date Offset (Opportunities)
  const oppEstApprovalDueDateOffsetMaster = computed<number>(() => {
    const index = settings.value.findIndex(v => v.key === 'oppEstApprovalDueDateOffsetMaster')
    if (index !== -1) {
      if (settings.value[index].number_value !== null && typeof settings.value[index].number_value === 'number') {
        return settings.value[index].number_value as number
      } else return 0
    } else return 0
  })

  // Opportunity Default Principal ID (Opportunities)
  const oppDefaultPrincipalId = computed<string | null>(() => {
    const index = settings.value.findIndex(v => v.key === 'oppDefaultPrincipalId')
    if (index !== -1) {
      if (settings.value[index].string_value !== null && typeof settings.value[index].string_value === 'string') {
        return settings.value[index].string_value as string
      } else return null
    } else return null
  })

  // Floating Holidays
  const floatingHolidays = computed<number>(() => {
    const index = settings.value.findIndex(v => v.key === 'floatingHolidays')
    if (index !== -1) {
      if (settings.value[index].number_value !== null && typeof settings.value[index].number_value === 'number') {
        return settings.value[index].number_value as number
      } else return 0
    } else return 0
  })

  // Planned Billings Snapshot Day
  const plannedBillingsSnapshotDay = computed<number>(() => {
    const index = settings.value.findIndex(v => v.key === 'plannedBillingsSnapshotDay')
    if (index !== -1) {
      if (settings.value[index].number_value !== null && typeof settings.value[index].number_value === 'number') {
        return settings.value[index].number_value as number
      } else return 0
    } else return 0
  })

  // Planned Billings Snapshot Time
  const plannedBillingsSnapshotTime = computed<string | null>(() => {
    const index = settings.value.findIndex(v => v.key === 'plannedBillingsSnapshotTime')
    if (index !== -1) {
      if (settings.value[index].string_value !== null && typeof settings.value[index].string_value === 'string') {
        const time = settings.value[index].string_value as string
        const [hours, minutes] = time.split(':').map(Number)
        const date = new Date(Date.UTC(1970, 0, 1, hours, minutes))
        const formattedHours = date.getHours().toString().padStart(2, '0')
        const formattedMinutes = date.getMinutes().toString().padStart(2, '0')
        return `${formattedHours}:${formattedMinutes}`
      } else return null
    } else return null
  })

  // ----------------------------------------
  // Functions
  // ----------------------------------------
  // Get Settings
  async function getSettings() {
    const { data, error } = await api.settings.getAll()
    if (!error && data) {
      settings.value = data
      return { data }
    } else return { error }
  }
  // Update Setting
  async function updateSetting(key: string, stringValue?: string, numberValue?: number, boolValue?: boolean) {
    let data, error
    const index = settings.value.findIndex(v => v.key === key)
    if (stringValue !== undefined && stringValue !== null) {
      // const { data: stringData, error: stringError } = await supabase.from('settings').update({ string_value: stringValue }).eq('key', key).select('*').single()
      const { data: stringData, error: stringError } = await api.settings.update(key, { string_value: stringValue })
      data = stringData
      error = stringError
    } else if (numberValue !== undefined && numberValue !== null) {
      const { data: numData, error: numError } = await api.settings.update(key, { number_value: numberValue })
      data = numData
      error = numError
    } else if (boolValue !== undefined && boolValue !== null) {
      const { data: boolData, error: boolError } = await api.settings.update(key, { bool_value: boolValue })
      data = boolData
      error = boolError
    }
    if (!error && data) {
      if (index !== -1) settings.value[index] = data
      else settings.value.push(data)
      return { data }
    } else return { error }
  }

  async function updateSettingString(key: string, value: string) { return await updateSetting(key, value) }
  async function updateSettingNumber(key: string, value: number) { return await updateSetting(key, undefined, value) }
  async function updateSettingBool(key: string, value: boolean) { return await updateSetting(key, undefined, undefined, value) }

  async function removeSetting(key: string) {
    const index = settings.value.findIndex(v => v.key === key)
    if (index !== -1) {
      const { data, error } = await api.settings.hardDelete(key)
      if (!error && data) {
        settings.value.splice(index, 1)
        return { data, error: undefined }
      } else return { error, data: undefined }
    }
    return { error: 'Unknown error' }
  }

  return {
    settings,
    getSettings,
    updateSetting,
    updateSettingString,
    updateSettingNumber,
    updateSettingBool,
    removeSetting,
    weekStartsOn,
    oppEstApprovalDueDateOffset,
    oppEstApprovalDueDateOffsetMaster,
    oppDefaultPrincipalId,
    timeEntryInterval,
    floatingHolidays,
    plannedBillingsSnapshotDay,
    plannedBillingsSnapshotTime
  }
})
