// ========================================
// ChannelMessage Type
// ========================================
import { BaseInput, Nullable, PartialOrNull, INumberedEntity, ICreated } from 'src/types'

// ----------------------------------------
// ChannelMessage Fields
// ----------------------------------------

// Required fields
export type ChannelMessageRequiredFields = {
  channel_id: number
  employee_id: string
  message: string
} & INumberedEntity & ICreated

// Required field names (for validation)
const requiredFields: Array<keyof ChannelMessageRequiredFields> = [
  'channel_id',
  'employee_id',
  'message'
]

// Nullable fields
export type ChannelMessageNullableFields = {
  color: string | null
}

// ----------------------------------------
// ChannelMessage Interface
// ----------------------------------------
export interface ChannelMessage extends ChannelMessageRequiredFields, ChannelMessageNullableFields { }

// ----------------------------------------
// ChannelMessage Input Class
// ----------------------------------------
type ChannelMessageCreationFields = Omit<Nullable<ChannelMessageRequiredFields & PartialOrNull<ChannelMessageNullableFields>>, 'id' | 'created_at'> // required fields can be null for creation
type ChannelMessageInputFields = PartialOrNull<ChannelMessageCreationFields> // all fields can be optional for creation

export class ChannelMessageInput extends BaseInput implements ChannelMessageCreationFields {
  static requiredFields = requiredFields
  static entityName = 'ChannelMessage'

  channel_id: number | null = null
  employee_id: string | null = null
  message: string | null = null
  color?: string | null

  constructor(fields: ChannelMessageInputFields = {}) {
    super()
    const clonedFields: ChannelMessageInputFields = BaseInput.deepClone(fields) as ChannelMessageInputFields
    // Remove any properties from fields that don't exist in Input type
    Object.keys(clonedFields).forEach((key) => { if (!(key in this)) delete clonedFields[key as keyof ChannelMessageInputFields] })
    Object.assign(this, clonedFields)
  }

  static isValid(instance: ChannelMessageInput): boolean {
    return this.validateRequiredFields(instance, this.requiredFields)
  }
}
