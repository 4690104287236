import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'

export const useIndexedDBStore = defineStore('indexedDB', () => {
  const db: Ref<IDBDatabase | null> = ref(null)

  function createDefaultStores() {
    if (!db.value) return
    if (!db.value.objectStoreNames.contains('avatars')) {
      db.value.createObjectStore('avatars')
    }
    if (!db.value.objectStoreNames.contains('brandImages')) {
      db.value.createObjectStore('brandImages')
    }
  }

  async function initializeDB(): Promise<void> {
    if (db.value !== null) return Promise.resolve()
    return new Promise((resolve, reject) => {
      const dbOpenRequest = window.indexedDB.open('levur', 1) // 'levur' is the name of the database, 1 is the version number

      dbOpenRequest.onupgradeneeded = (event) => {
        db.value = (event.target as IDBOpenDBRequest).result
        createDefaultStores()
      }
      dbOpenRequest.onsuccess = function (event) {
        db.value = (event.target as IDBOpenDBRequest).result
        resolve()
      }
      dbOpenRequest.onerror = function () {
        reject()
      }
    })
  }

  function addAvatar(avatarUrl: string, avatarBlob: Blob) {
    if (!db.value) return
    const transaction = db.value.transaction(['avatars'], 'readwrite')
    const objectStore = transaction.objectStore('avatars')
    objectStore.add(avatarBlob, avatarUrl)
  }
  async function getAvatar(avatarUrl: string): Promise<Blob | null> {
    return new Promise((resolve, reject) => {
      if (!db.value) {
        reject(new Error('Database not initialized'))
        return
      }
      const transaction = db.value.transaction(['avatars'], 'readonly')
      const objectStore = transaction.objectStore('avatars')
      const request = objectStore.get(avatarUrl)

      request.onsuccess = function () {
        resolve(request.result as Blob | null)
      }

      request.onerror = function () {
        reject(new Error('Error retrieving avatar'))
      }
    })
  }

  function addBrandImage(fileName: string, image: Blob) {
    if (!db.value) return
    const transaction = db.value.transaction(['brandImages'], 'readwrite')
    const objectStore = transaction.objectStore('brandImages')
    objectStore.add(image, fileName)
  }
  async function getBrandImage(fileName: string): Promise<Blob | null> {
    return new Promise((resolve, reject) => {
      if (!db.value) {
        reject(new Error('Database not initialized'))
        return
      }
      const transaction = db.value.transaction(['brandImages'], 'readonly')
      const objectStore = transaction.objectStore('brandImages')
      const request = objectStore.get(fileName)

      request.onsuccess = function () {
        resolve(request.result as Blob | null)
      }

      request.onerror = function () {
        reject(new Error('Error retrieving brand image'))
      }
    })
  }
  async function getAllBrandImages(): Promise<{ name: string, file: Blob }[]> {
    return new Promise((resolve, reject) => {
      if (!db.value) {
        reject(new Error('Database not initialized'))
        return
      }
      const transaction = db.value.transaction(['brandImages'], 'readonly')
      const objectStore = transaction.objectStore('brandImages')
      const request = objectStore.getAll()

      const results: { name: string, file: Blob }[] = []

      request.onsuccess = function (event: Event) {
        const target = event.target as IDBRequest<IDBCursorWithValue | null>
        const cursor = target.result
        if (cursor) {
          const name = cursor.key as string
          const file = cursor.value
          results.push({ name, file })
          cursor.continue()
        } else {
          resolve(results)
        }
      }

      request.onerror = function () {
        reject(new Error('Error retrieving brand images'))
      }
    })
  }

  function deleteBrandImage(fileName: string) {
    if (!db.value) return
    const transaction = db.value.transaction(['brandImages'], 'readwrite')
    const objectStore = transaction.objectStore('brandImages')
    objectStore.delete(fileName)
  }

  return {
    db,
    initializeDB,
    getAvatar,
    addAvatar,
    getBrandImage,
    addBrandImage,
    deleteBrandImage,
    getAllBrandImages
  }
})
