export interface DateTimeTimeZone {
  dateTime: string,
  timeZone: string
}

export interface EmailAddress {
  address: string,
  name?: string
}

export interface Recipient {
  emailAddress: EmailAddress
}

export interface ItemBody {
  content: string,
  contentType: string
}

export interface EmailCategory { // aka Tags
  id: string,
  displayName: string,
  color?: string,
}

// this is not a full list of all possible properties but just the ones we select and use
export interface DistributionList {
  id: string,
  displayName: string,
  groupTypes: string[],
  mail: string, // email address
  mailEnabled: boolean,
  securityEnabled: boolean,
}

export class BatchRequest {
  id: number | string
  method: string
  url: string
  body?: unknown // the json body of the request
  headers = { 'Content-Type': 'application/json' }

  constructor(id: number | string, method: string, url: string, body?: unknown) {
    this.id = id
    this.method = method
    this.url = url
    this.body = body
  }
}
