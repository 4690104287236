import { ref, computed, type Ref, watch } from 'vue'
import { useQuasar } from 'quasar'
import { defineStore } from 'pinia'
import { useUserStore } from 'src/stores/user'
import { getSortQuery, sortFlaggedMessages, sortMessagesBySentDateAscending } from 'src/utils/email'
import * as api from 'src/api'
// Types
import { SendMethod, EmailFolder, EmailMessage, EmailMessagePreview, EmailCategory, ResponseTypes, EmailMessageToSend, FollowupFlag, FilterMethod, SortDirection } from 'src/types/email'
import { SearchMessagesOptions } from 'src/api/email'
import { EmailSignature } from 'src/types'

export type ProjectTag = {
  projectName: string
  projectNumber: string
  projectId: number
  email: {
    id: number
    employee_id: string
    message_id: string
  }
}

enum SearchIn {
  AllFolders = 'All Folders',
  CurrentFolder = 'Current Folder'
}

// Internal use only
const folderSortOrder = ['Inbox', 'Drafts', 'Archive', 'Sent Items', 'Outbox', 'Deleted Items', 'Junk Email']
const foldersToHide = ['Conversation History', 'Sync Issues', 'RSS Feeds']

export const useEmailStore = defineStore('email', () => {
  const $q = useQuasar()
  const userStore = useUserStore()

  // ------------------------------------------------
  // Constants
  // ------------------------------------------------
  const messageCountPerCall: number = 30
  const projectTagPrefix: string = 'Project:'
  const searchInOptions: SearchIn[] = [SearchIn.AllFolders, SearchIn.CurrentFolder]

  // ------------------------------------------------
  // State
  // ------------------------------------------------
  // State (General)
  const initialRefreshCompleted: Ref<boolean> = ref(false)
  const unreadCountInbox = ref(0)
  const inboxFolderId = computed<string | null>(() => allFolders.value.find(folder => folder.displayName === 'Inbox')?.id || null)
  const deletedFolderId = computed<string | null>(() => allFolders.value.find(folder => folder.displayName === 'Deleted Items')?.id || null)
  const markAsReadAfterSeconds = computed<number>(() => userStore.settings.find(setting => setting.key === 'email_mark_read_after_seconds')?.number_value || 0) // Set to -1 to disable
  const enableConversationMode = computed<boolean>(() => userStore.settings.find(setting => setting.key === 'email_conversation_mode')?.bool_value || false)

  // State (Loading)
  const loadingMessagePreviews: Ref<boolean> = ref(false)
  const loadingMessageId: Ref<string | null> = ref(null)
  const loadingSubFolderId: Ref<string | null> = ref(null)
  const updatingMessageId: Ref<string | null> = ref(null)
  const loading = computed(() => loadingMessagePreviews.value || loadingMessageId.value || loadingSubFolderId.value || updatingMessageId.value)

  // State (Messages)
  const inboxMessages: Ref<EmailMessagePreview[]> = ref([])
  const unreadInboxMessages: Ref<EmailMessagePreview[]> = ref([])
  const otherMessages: Ref<EmailMessagePreview[]> = ref([])
  const messages = computed<EmailMessagePreview[]>(() => {
    // inbox with default filtering and sorting
    if (viewingInboxMessages.value) return inboxMessages.value
    // unread inbox messages with default sorting
    else if (viewingUnreadInboxMessages.value) return unreadInboxMessages.value
    // all others
    else return otherMessages.value
  })

  // State (Selected)
  const selectedFolderId: Ref<string | null> = ref(null)
  const selectedMessage: Ref<EmailMessage | null> = ref(null)
  const multiSelectedMessages: Ref<EmailMessagePreview[]> = ref([])
  const selectedTagName: Ref<string | null> = ref(null)
  const isInboxSelected = computed<boolean>(() => selectedFolder.value?.displayName === 'Inbox')
  const viewingInboxMessages = computed<boolean>(() => selectedFolder.value?.displayName === 'Inbox' && selectedFiltering.value === FilterMethod.All && selectedSort.value === SortDirection.Descending)
  const viewingUnreadInboxMessages = computed<boolean>(() => selectedFolder.value?.displayName === 'Inbox' && selectedFiltering.value === FilterMethod.Unread && selectedSort.value === SortDirection.Descending)
  const selectedTagId = computed<string | null>(() => selectedTagName.value ? emailTags.value.find(tag => tag.displayName === selectedTagName.value)?.id || null : null)
  const selectedFolder = computed<EmailFolder | null>(() => selectedFolderId.value ? findFolderById(selectedFolderId?.value) || null : null)
  const signatureIdToAddToEmailMessage = ref<number | null>(null)
  const projectTags: Ref<ProjectTag[]> = ref([])

  // State (Filter/Sort/Search)
  const selectedFiltering: Ref<FilterMethod> = ref(FilterMethod.All)
  const selectedSort: Ref<SortDirection> = ref(SortDirection.Descending)
  const searchText: Ref<string> = ref('')
  const submittedSearchText: Ref<string> = ref('') // saved search text after submitting for display purposes
  const searchIn: Ref<SearchIn> = ref(searchInOptions[0])
  const searchPaginationUrl = ref<string | null>(null) // used to store the search "nextLink" url for pagination

  // State (Lists)
  const emailTags: Ref<EmailCategory[]> = ref([])
  const allFolders: Ref<EmailFolder[]> = ref([])
  const mainFolders = computed<EmailFolder[]>(() => allFolders.value.filter(folder => folderSortOrder.includes(folder.displayName)))
  const otherFolders = computed<EmailFolder[]>(() => allFolders.value.filter(folder => !folderSortOrder.includes(folder.displayName)))
  const signatures: Ref<EmailSignature[]> = ref([])

  // State (Composing/Sending)
  const isComposing: Ref<boolean> = ref(false)
  const isComposedMessageModified = ref(false)
  const sendMethod: Ref<SendMethod> = ref(SendMethod.New)

  // ------------------------------------------------
  // Watchers
  // ------------------------------------------------

  // after clicking away from unread inbox sorting, remove any read messages from the unreadInboxMessage list
  watch(() => viewingUnreadInboxMessages.value, () => {
    if (!viewingUnreadInboxMessages.value) unreadInboxMessages.value = unreadInboxMessages.value.filter(message => !message.isRead)
  })

  // ------------------------------------------------
  // Functions (Internal)
  // ------------------------------------------------
  /** Handles removing messagePreview from whatever list it is stored in.
   *  @param message The messagePreview to remove
   *  @param keepInTagList keep messages visible in tagged list unless they are "deleted". This would be false if you are "moving" or "archiving a message in a tagged list".
   */
  function removeMessagePreview(message: EmailMessagePreview, removeFromTagList = false) { // Used by deleteSelectedMessage(), archiveSelectedMessage, moveSelectedMessageToFolder()
    if (selectedTagName.value && !removeFromTagList) return
    if (viewingInboxMessages.value || viewingUnreadInboxMessages.value || message.parentFolderId === inboxFolderId.value) {
      // removing from inbox? remove from inboxMessage list and inboxUnreadMessage list if unread
      removeMessagePreviewsFromInboxList(message)
      // if viewing the tag list you may still need to remove the message from the tag list
      if (selectedFiltering.value === FilterMethod.Tagged) {
        const index = otherMessages.value.findIndex(m => m.id === message.id)
        if (index > -1) otherMessages.value.splice(index, 1)
      }
    }
    else {
      const index = otherMessages.value.findIndex(m => m.id === message.id)
      if (index > -1) otherMessages.value.splice(index, 1)
    }
  }

  function updateMessagePreview(id: string, itemToUpdate: Partial<EmailMessagePreview>) {
    if (!selectedMessage.value) return
    if (viewingInboxMessages.value || viewingUnreadInboxMessages.value) {
      const foundMessage = inboxMessages.value.find(message => message.id === id)
      if (foundMessage) Object.assign(foundMessage, itemToUpdate)
    }
    if (viewingUnreadInboxMessages.value) {
      const foundMessage = unreadInboxMessages.value.find(message => message.id === id)
      if (foundMessage) Object.assign(foundMessage, itemToUpdate)
    }
    else {
      const foundMessage = otherMessages.value.find(message => message.id === id)
      if (foundMessage) Object.assign(foundMessage, itemToUpdate)
    }
  }

  function addMessagePreviewToInboxList(messageToAdd: EmailMessagePreview, isRead?: boolean) {
    // add message into inboxMessages in the appropriate place based on sort order and sent date
    const index = inboxMessages.value.findIndex(message => message.sentDateTime < messageToAdd.sentDateTime)
    if (index > -1) inboxMessages.value.splice(index, 0, messageToAdd)
    else inboxMessages.value.push(messageToAdd)
    if (!isRead) addMessagePreviewToUnreadInboxList(messageToAdd)
  }

  function addMessagePreviewToUnreadInboxList(messageToAdd: EmailMessagePreview) {
    if (viewingUnreadInboxMessages.value) return // don't add to unread list if viewing unread list
    const unreadIndex = unreadInboxMessages.value.findIndex(message => message.sentDateTime < messageToAdd.sentDateTime)
    if (unreadIndex > -1) unreadInboxMessages.value.splice(unreadIndex, 0, messageToAdd)
    else unreadInboxMessages.value.push(messageToAdd)
  }

  function removeMessagePreviewsFromInboxList(messageToRemove: EmailMessagePreview) {
    inboxMessages.value = inboxMessages.value.filter(message => message.id !== messageToRemove.id)
    removeMessagePreviewFromUnreadInboxList(messageToRemove)
  }

  function removeMessagePreviewFromUnreadInboxList(messageToRemove: EmailMessagePreview) {
    unreadInboxMessages.value = unreadInboxMessages.value.filter(message => message.id !== messageToRemove.id)
  }

  // ------------------------------------------------
  // Functions (General)
  // ------------------------------------------------

  async function initialRefresh() { // called by App.vue on user login
    initialRefreshCompleted.value = false
    await Promise.all([
      refreshUnreadCountInbox(),
      refreshFolders(),
      getEmailTags()
    ])
    await setInboxAsSelected()
    refreshUnreadInboxMessages()
    initialRefreshCompleted.value = true
  }

  /** Resets the store to its initial state */
  function reset() {
    selectedMessage.value = null
    selectedTagName.value = null
    selectedFiltering.value = FilterMethod.All
    selectedSort.value = SortDirection.Descending
    searchText.value = ''
    submittedSearchText.value = ''
    searchPaginationUrl.value = null
    searchIn.value = SearchIn.AllFolders
    isComposing.value = false
    isComposedMessageModified.value = false
    otherMessages.value = []
  }

  async function handleIncomingNotification() {
    let amountToGet = messageCountPerCall
    if (selectedMessage.value && inboxFolderId.value) {
      // if viewing inboxMessageList and have a selected message, retrieve enough messages to keep the selected message in the inboxMessages list
      if (viewingInboxMessages.value) {
        const currentSelectedMessageIndex = inboxMessages.value.findIndex(message => message.id === selectedMessage.value?.id)
        if (currentSelectedMessageIndex > messageCountPerCall) amountToGet = currentSelectedMessageIndex + 10
      }
      // if viewing unreadInboxMessageList and have a selected message, retrieve enough messages to keep the selected message in the unreadInboxMessages list
      else if (viewingUnreadInboxMessages.value) {
        const currentSelectedMessageIndex = unreadInboxMessages.value.findIndex(message => message.id === selectedMessage.value?.id)
        if (currentSelectedMessageIndex > messageCountPerCall) amountToGet = currentSelectedMessageIndex + 10
      }
    }
    refreshUnreadCountInbox()
    refreshUnreadInboxMessages(amountToGet)
    refreshInboxMessages(amountToGet)
  }

  // ------------------------------------------------
  // Handle Selecting Messages and Sorting
  // ------------------------------------------------

  /** Selecting a new folder and getting messages for that folder  */
  async function selectFolderAndGetMessages(folderId: string, keepSelectedMessage = false) {
    if (folderId === selectedFolderId.value) return

    selectedFolderId.value = folderId
    if (!keepSelectedMessage) {
      isComposing.value = false
      selectedMessage.value = null
      multiSelectedMessages.value = []
    }
    selectedTagName.value = null
    selectedFiltering.value = FilterMethod.All
    selectedSort.value = SortDirection.Descending
    otherMessages.value = []
    await getMessagePreviews(1)
  }

  /** Filter by selectedTagName and get messages for that tag */
  async function filterByTagged(tagName: string) {
    if (tagName === selectedTagName.value) return

    isComposing.value = false
    selectedMessage.value = null
    multiSelectedMessages.value = []
    selectedFolderId.value = null
    selectedFiltering.value = FilterMethod.Tagged
    selectedSort.value = SortDirection.Descending
    selectedTagName.value = tagName
    otherMessages.value = []
    await getMessagePreviews(1)
  }

  /** Filter by "Unread" "Flagged" "Search" "Tagged" "All" and get messages  */
  function filterMessagesBy(filtering: FilterMethod) {
    loadingMessagePreviews.value = true
    isComposing.value = false
    selectedMessage.value = null
    multiSelectedMessages.value = []
    otherMessages.value = []
    selectedFiltering.value = filtering
    getMessagePreviews(1)
  }

  /** Sort by "Ascending" "Descending" and get messages */
  function sortMessagesBy(sort: SortDirection) {
    if (sort === selectedSort.value) return
    isComposing.value = false
    selectedMessage.value = null
    multiSelectedMessages.value = []
    otherMessages.value = []
    selectedSort.value = sort
    getMessagePreviews(1)
  }

  function createNewMessage() {
    isComposing.value = false
    selectedMessage.value = null
    multiSelectedMessages.value = []
    sendMethod.value = SendMethod.New
    isComposing.value = true
  }

  async function selectMessage(messageId: string): Promise<void> {
    multiSelectedMessages.value = []
    if (messageId === selectedMessage.value?.id) return
    const { data, error } = await api.email.getFullMessage(messageId)
    if (data && !error) {
      selectedMessage.value = data
      multiSelectedMessages.value.push(new EmailMessagePreview(data))
      markMessageAsReadAfterSeconds()
      if (data.isDraft === true) isComposing.value = true
      else isComposing.value = false
    }
  }

  async function setSelectedMessageReadStatus(isRead: boolean) {
    if (!selectedMessage.value) return
    updatingMessageId.value = selectedMessage.value.id
    const { data, error } = await api.email.updateMessage(selectedMessage.value.id, { isRead })
    if (!error && data) {
      selectedMessage.value.isRead = data.isRead
      updateMessagePreview(data.id, { isRead: data.isRead })
      updateFolderUnreadCount(data.parentFolderId, data.isRead ? -1 : 1)
      if (selectedMessage.value?.parentFolderId === inboxFolderId.value) {
        if (!data.isRead) addMessagePreviewToUnreadInboxList(data)
        else if (data.isRead && !viewingUnreadInboxMessages.value) removeMessagePreviewFromUnreadInboxList(data)
      }
    }
    updatingMessageId.value = null
  }

  const markAsReadTimers: Ref<ReturnType<typeof setTimeout>[]> = ref([])
  function markMessageAsReadAfterSeconds() {
    if (!selectedMessage.value) return
    // First, clear any markAsReadTimers
    markAsReadTimers.value.forEach(timer => clearTimeout(timer))
    // Next, check if this message is unread, and if so, create a new markAsReadTimer
    if (!selectedMessage.value.isRead && markAsReadAfterSeconds.value !== null) {
      if (markAsReadAfterSeconds.value === 0) setSelectedMessageReadStatus(true)
      else if (markAsReadAfterSeconds.value > 0) {
        const timer = setTimeout(async () => {
          setSelectedMessageReadStatus(true)
        }, markAsReadAfterSeconds.value * 1000)
        markAsReadTimers.value.push(timer)
      }
    }
  }

  // ------------------------------------------------
  // Functions (Search)
  // ------------------------------------------------
  function resetSearch() {
    searchIn.value = SearchIn.AllFolders // set to default
    searchText.value = ''
    submittedSearchText.value = ''
    searchPaginationUrl.value = null

    if (!selectedFolderId.value && inboxFolderId.value) {
      selectedTagName.value = null
      setInboxAsSelected()
    }
    else refreshCurrentFolderMessages()
  }

  function search() {
    otherMessages.value = []
    searchPaginationUrl.value = null
    submittedSearchText.value = searchText.value
    selectedFiltering.value = FilterMethod.Search
    if (searchText.value && searchIn.value === SearchIn.AllFolders) {
      selectedFolderId.value = null
      selectedTagName.value = null
      selectedMessage.value = null
    }
    if (searchIn.value === SearchIn.CurrentFolder && selectedFolderId.value) {
      selectedTagName.value = null
      selectedMessage.value = null
    }
    getMessagePreviews(1)
  }

  // ------------------------------------------------
  // Functions (Folders)
  // ------------------------------------------------

  function setInboxAsSelected() {
    if (initialRefreshCompleted.value && inboxFolderId.value) selectFolderAndGetMessages(inboxFolderId.value)
  }

  async function refreshFolders() {
    const { data, error } = await api.email.getAllFolders()
    if (!error && data) {
      const newFolders = data.filter(folder => !foldersToHide.includes(folder.displayName))
      // sort folders by sort order
      newFolders.sort((a, b) => {
        const aIndex = folderSortOrder.indexOf(a.displayName)
        const bIndex = folderSortOrder.indexOf(b.displayName)
        if (aIndex === -1 && bIndex === -1) return 0
        if (aIndex === -1) return 1
        if (bIndex === -1) return -1
        return aIndex - bIndex
      })
      allFolders.value = newFolders
    }
  }

  function addFolderToList(folder: EmailFolder, parentFolderId?: string) {
    if (!parentFolderId) allFolders.value.push(folder)
    else {
      const parentFolder = findFolderById(parentFolderId)
      if (!parentFolder) return
      if (parentFolder.childFolders) parentFolder.childFolders.push(folder)
      else parentFolder.childFolders = [folder]
      parentFolder.childFolderCount += 1
    }
  }

  function removeFolderFromList(folderId: string) {
    const folder = findFolderById(folderId)
    if (!folder) return

    const parentFolder = findFolderById(folder.parentFolderId)
    if (parentFolder) {
      if (parentFolder.childFolders && parentFolder.childFolders.length > 0) {
        const index = parentFolder.childFolders.indexOf(folder)
        if (index > -1) parentFolder.childFolders.splice(index, 1)
        parentFolder.childFolderCount -= 1
      }
    } else {
      const index = allFolders.value.indexOf(folder)
      if (index > -1) allFolders.value.splice(index, 1)
    }
  }

  /** Recursively search the folder list to find a folder by its ID within the folder structure. This is needed now that the folder list can have nested folders. */
  function findFolderByIdRecursively(folderId: string, currentFolder: EmailFolder): EmailFolder | null {
    if (currentFolder.id === folderId) return currentFolder
    if (currentFolder.childFolders) {
      for (const childFolder of currentFolder.childFolders) {
        const result = findFolderByIdRecursively(folderId, childFolder)
        if (result) return result
      }
    }
    return null
  }

  /** Find a folder by its ID within the collection of folders.
   * @param folderId The ID of the folder to find. */
  function findFolderById(folderId: string): EmailFolder | null {
    for (const folder of allFolders.value) {
      const result = findFolderByIdRecursively(folderId, folder)
      if (result) return result
    }
    return null
  }

  /** Add or subtract specified amount from folder unread count
   *  @param folderId The ID of the folder to update
   *  @param amount The amount to add or subtract Ex: 1 or -1
   */
  function updateFolderUnreadCount(folderId: string, amount: number) {
    const folder = findFolderById(folderId)
    if (folder) folder.unreadItemCount += amount
    // also update inbox unread count if message is in inbox
    if (folderId === inboxFolderId.value) unreadCountInbox.value += amount
  }

  async function refreshUnreadCountInbox() {
    const { data, error } = await api.email.getUnreadCount('inbox')
    if (!error && data) {
      if (data === unreadCountInbox.value) return
      const inboxFolder = allFolders.value.find(folder => folder.id === inboxFolderId.value)
      if (inboxFolder) inboxFolder.unreadItemCount = data
      unreadCountInbox.value = data
    }
  }

  // ------------------------------------------------
  // Functions (Sending Messages / Saving Drafts)
  // ------------------------------------------------
  async function sendMessage(messageToSend: EmailMessageToSend) {
    let result: { data?: unknown, error?: string } | null = null

    let apiFunction
    if (selectedMessage.value) {
      if (selectedMessage.value?.isDraft) {
        // must update drafts before sending otherwise it will send the draft at the last saved point
        const response = await api.email.updateMessage(selectedMessage.value.id, messageToSend)
        if (response.error) return $q.notify({ type: 'negative', message: 'Failed to send message. ' + response.error })
        result = await api.email.sendDraftMessage(response.data.id)
        if (result) {
          removeMessagePreview(selectedMessage.value)
          selectedMessage.value = null
        }
        else return $q.notify({ type: 'negative', message: 'Failed to send message.' })
      }
      else if (sendMethod.value === 'reply' || sendMethod.value === 'replyAll') apiFunction = api.email.replyToEmailMessage
      else if (sendMethod.value === 'forward') apiFunction = api.email.forwardEmailMessage
    }

    if (!result && !apiFunction) result = await api.email.sendNewEmailMessage(messageToSend)
    else if (!result && apiFunction && selectedMessage.value) result = await apiFunction(selectedMessage.value.id, messageToSend)

    // If the message was sent successfully
    if (result && !result.error && result.data) {
      $q.notify({ type: 'positive', message: 'Message sent' })
      isComposing.value = false
      isComposedMessageModified.value = false
    } else if (!result || result.error) $q.notify({ type: 'negative', message: 'Failed to send message. ' + (result?.error || '') })
  }

  /** Creates or updates a draft with the new message.  Updates the message list and returns the updated message when successful. */
  async function saveDraft(newDraftMessage: EmailMessageToSend): Promise<void> {
    const draftMessageId = selectedMessage.value?.id || null

    // Save the draft (calling the correct API function)
    let result: { data?: unknown, error?: string } | null = null
    if (sendMethod.value === 'new') {
      result = await api.email.createDraft(newDraftMessage)
    } else if (selectedMessage.value) {
      let apiFunction
      if (selectedMessage.value && selectedMessage.value.isDraft) apiFunction = api.email.updateMessage
      else if (sendMethod.value === 'reply' || sendMethod.value === 'replyAll') apiFunction = api.email.createReplyDraft
      else if (sendMethod.value === 'forward') apiFunction = api.email.createForwardDraft
      if (apiFunction) result = await apiFunction(selectedMessage.value?.id, newDraftMessage)
    }

    // If the draft was saved successfully, update the message list
    if (result && !result.error && result.data) {
      const resultMessage = result.data as EmailMessage
      if (selectedFolder.value?.displayName === 'Drafts') {
        const draftToReplace = otherMessages.value.find(message => message.id === draftMessageId)
        if (draftToReplace) {
          const index = otherMessages.value.indexOf(draftToReplace)
          otherMessages.value.splice(index, 1, (new EmailMessagePreview(resultMessage))) // Replace the old draft
          otherMessages.value.unshift(otherMessages.value.splice(index, 1)[0]) // Move the new draft to the top
        } else otherMessages.value.unshift(new EmailMessagePreview(resultMessage)) // Add the new draft to the top
      }
      selectedMessage.value = resultMessage
      $q.notify({ type: 'positive', message: 'Draft saved' })
    } else $q.notify({ type: 'negative', message: 'Failed to save draft' })
  }

  // ------------------------------------------------
  // Functions (Updating Selected Message)
  // ------------------------------------------------
  async function multiDeleteSelectedMessages(deletedItemsFolderId?: string) {
    $q.loading.show()
    await api.email.batchDeleteMessages(multiSelectedMessages.value, deletedItemsFolderId)
    refreshFolders()
    refreshUnreadCountInbox()
    refreshInboxMessages()
    refreshUnreadInboxMessages()
    otherMessages.value = []
    await getMessagePreviews(1)
    $q.loading.hide()
    selectedMessage.value = null
    multiSelectedMessages.value = []
    isComposing.value = false
  }

  async function deleteSelectedMessage() {
    if (!selectedMessage.value) return
    const deletedItemsFolder = mainFolders.value.find((folder) => folder.displayName === 'Deleted Items')

    // if multi-selecting, delete all selected messages
    if (multiSelectedMessages.value.length > 1) return multiDeleteSelectedMessages(deletedItemsFolder?.id)

    // hard delete if message is in deleted items folder
    if (selectedMessage.value.parentFolderId === deletedItemsFolder?.id) await api.email.hardDeleteMessage(selectedMessage.value.id) // cannot capture response
    else {
      const { data, error } = await api.email.softDeleteMessage(selectedMessage.value.id)
      if (error || !data) return $q.notify({ message: 'Failed to Delete', type: 'negative' })
    }

    // if unread, subtract unread count from the previous folder and add to deleted items folder
    if (!selectedMessage.value.isRead && selectedMessage.value.parentFolderId) {
      updateFolderUnreadCount(selectedMessage.value.parentFolderId, -1)
      if (deletedItemsFolder) deletedItemsFolder.unreadItemCount += 1
      if (selectedMessage.value.parentFolderId === deletedItemsFolder?.id) deletedItemsFolder.unreadItemCount -= 1
    }
    if (selectedTagName.value && !selectedFolderId.value) removeMessagePreview(selectedMessage.value, true) // don't remove message if viewing a tag and not a folder
    else removeMessagePreview(selectedMessage.value)

    selectedMessage.value = null
    multiSelectedMessages.value = []
    isComposing.value = false
    return $q.notify({ message: 'Message Deleted', type: 'positive' })
  }

  async function moveManySelectedMessages(destinationFolderId: string) {
    $q.loading.show()
    await api.email.batchMoveMessages(multiSelectedMessages.value, destinationFolderId)
    refreshFolders()
    refreshUnreadCountInbox()
    refreshInboxMessages()
    refreshUnreadInboxMessages()
    otherMessages.value = []
    await getMessagePreviews(1)
    $q.loading.hide()
    selectedMessage.value = null
    multiSelectedMessages.value = []
    isComposing.value = false
  }

  function archiveSelectedMessage() {
    if (!selectedMessage.value || selectedFolder.value?.displayName === 'Archive') return
    const archiveFolderId = mainFolders.value.find((folder) => folder.displayName === 'Archive')?.id || 'archive' // can use default name here
    moveSelectedMessageToFolder(archiveFolderId)
  }

  async function moveSelectedMessageToFolder(folderId: string) {
    if (!selectedMessage.value || !folderId || selectedMessage.value.parentFolderId === folderId) return

    if (multiSelectedMessages.value.length > 1) return moveManySelectedMessages(folderId)

    // TODO Prevent moving sent messages to another folder. Remove this check when we are able to detect if a message is sent or not
    if (selectedMessage.value.parentFolderId === mainFolders.value.find((f) => f.displayName === 'Sent Items')?.id) return $q.notify({ message: 'Cannot Move Sent Items', type: 'negative' })

    const { data, error } = await api.email.moveMessage(selectedMessage.value.id, folderId)
    if (data && !error) {
      // new folder is inbox? add message to inboxMessage list and/or inboxUnreadMessage list
      if (data.parentFolderId === inboxFolderId.value) addMessagePreviewToInboxList(data, data.isRead)

      // if message is unread, update unread count for previous folder and new folder
      if (!data.isRead) {
        if (selectedMessage.value.parentFolderId) updateFolderUnreadCount(selectedMessage.value.parentFolderId, -1)
        updateFolderUnreadCount(data.parentFolderId, 1)
      }
      // the message id changes after moving folders
      if (selectedTagName.value) {
        // update the message preview in the tag list and selectedMessage if viewing tagged messages
        const oldMessageIndex = otherMessages.value.findIndex(message => message.id === selectedMessage.value?.id)
        const newMessagePreview = new EmailMessagePreview(data)
        if (oldMessageIndex >= 0) otherMessages.value.splice(oldMessageIndex, 1, newMessagePreview)
        selectedMessage.value = data
      }
      else {
        removeMessagePreview(selectedMessage.value)
        selectedMessage.value = null
        isComposing.value = false
      }
      multiSelectedMessages.value = []
      $q.notify({ message: 'Moved to Folder', type: 'positive' })
    } else $q.notify({ message: 'Failed to Move Message', type: 'negative' })
  }

  function updateEventResponseStatus(status: ResponseTypes): void {
    if (!selectedMessage.value || !selectedMessage.value.event) return
    selectedMessage.value.event.responseStatus.response = status
    updateMessagePreview(selectedMessage.value.id, { event: selectedMessage.value.event })
  }

  // ------------------------------------------------
  // Functions (Flagging Messages)
  // ------------------------------------------------
  async function markSelectedMessageFlagComplete(): Promise<void> {
    if (!selectedMessage.value) return
    const now = new Date()
    // MS API is overwriting the time portion to "00:00:00.0000000" while preserving the date. This makes the completed date appear as the day before. I add a day to correct it.
    now.setDate(now.getDate() + 1)
    const completedFlag: FollowupFlag = {
      flagStatus: 'complete',
      completedDateTime: {
        dateTime: now.toISOString(),
        timeZone: 'UTC'
      }
    }
    const { data, error } = await api.email.updateMessage(selectedMessage.value.id, { flag: completedFlag })
    if (!error && data) {
      selectedMessage.value.flag = data.flag
      updateMessagePreview(data.id, { flag: data.flag })
      $q.notify({ message: 'Flag Marked Complete', type: 'positive' })
    } else $q.notify({ message: 'Failed to Mark Flag Complete', type: 'negative' })
  }

  async function flagSelectedMessage(flagObj: FollowupFlag): Promise<void> {
    if (!selectedMessage.value) return
    const { data, error } = await api.email.updateMessage(selectedMessage.value.id, { flag: flagObj })
    if (data && !error) {
      selectedMessage.value.flag = data.flag
      updateMessagePreview(data.id, { flag: data.flag })
      if (selectedFiltering.value === FilterMethod.Flagged) sortFlaggedMessages(otherMessages.value) // update the order in message list
      $q.notify({ message: 'Flagged for Follow up', type: 'positive' })
    } else $q.notify({ message: 'Failed to Flag', type: 'negative' })
  }

  // ------------------------------------------------
  // Functions (Tags)
  // ------------------------------------------------

  // Shared email Tags
  async function getProjectTags() {
    if (!selectedMessage.value) return
    projectTags.value = []
    // Get all the projects that this email is tagged with
    const { data, error } = await api.projectEmails.getAll({ select: 'project_id, project:projects (name, number, id), email:emails (id, message_id, employee_id)', filters: [{ by: 'message_id', op: 'eq', value: selectedMessage.value.id }] })

    if (!error && data) {
      for (let i = 0; i < data.length; i++) {
        const newProjectTag: ProjectTag = {
          projectName: data[i].project.name,
          projectNumber: data[i].project.number,
          projectId: data[i].project_id,
          email: data[i].email
        }
        projectTags.value.push(newProjectTag)
      }
    }
  }

  function addProjectTag(projectTag: ProjectTag) {
    // First, check if we already have a project tag for this project id
    const existingProjectTag = projectTags.value.find(pt => pt.projectId === projectTag.projectId)
    // If we didn't find one, just add the new projectTag and return
    if (!existingProjectTag) {
      projectTags.value.push(projectTag)
      return
    }
    // If we did find one, replace the existing projectTag with the new one
    const index = projectTags.value.indexOf(existingProjectTag)
    projectTags.value.splice(index, 1, projectTag)
  }

  async function removeProjectTag(projectTag: ProjectTag) {
    const index = projectTags.value.findIndex(p => p.projectId === projectTag.projectId)
    if (index > -1) projectTags.value.splice(index, 1)
  }

  // Filtering Emails by Tags
  async function getEmailTags(): Promise<void> {
    const { data, error } = await api.email.getAllTags()
    if (data && !error) emailTags.value = data
  }

  async function removeTagFromSelectedMessage(tagName: string) {
    if (!selectedMessage.value) return
    let tags = selectedMessage.value.categories
    tags = tags.filter(tag => tag !== tagName)

    const { data, error } = await api.email.updateTagList(selectedMessage.value.id, tags)
    if (data && !error) {
      selectedMessage.value = data
      $q.notify({ message: 'Tag Removed', type: 'positive' })
    } else $q.notify({ message: 'Failed to Remove Tag', type: 'negative' })
  }

  async function clearTagsFromSelectedMessage() {
    if (!selectedMessage.value) return
    const { data, error } = await api.email.updateTagList(selectedMessage.value.id, [])
    if (data && !error) {
      selectedMessage.value.categories = data.categories
      $q.notify({ message: 'Tags Cleared', type: 'positive' })
    } else $q.notify({ message: 'Failed to Clear Tags', type: 'negative' })
  }

  // ------------------------------------------------
  // Functions (Email Attachments)
  // ------------------------------------------------

  async function getEmailAttachmentAsBlob(attachmentInfo: { messageId: string, attachment: { id: string, name: string, contentType: string } }): Promise<Blob | undefined> {
    const { data, error } = await api.email.getAttachment(attachmentInfo.messageId, attachmentInfo.attachment.id)
    if (error) return

    let blob: Blob | null = null
    if (data instanceof Blob) blob = data
    else if (data instanceof ReadableStream) { // pdf's are returned as a ReadableStream
      const chunks = []
      const reader = data.getReader()

      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        chunks.push(value)
      }
      blob = new Blob(chunks, { type: attachmentInfo.attachment.contentType })
    }
    if (blob) return blob
  }

  async function downloadEmailAttachment(attachmentInfo: { messageId: string, attachment: { id: string, name: string, contentType: string } }) {
    const blob = await getEmailAttachmentAsBlob(attachmentInfo)
    if (!blob) return
    const url = window.URL.createObjectURL(blob)

    // sets a link in the downloads section of the browser
    const link = document.createElement('a')
    link.setAttribute('download', attachmentInfo.attachment.name) // Specify the filename for the download
    document.body.appendChild(link)

    link.href = url
    link.click()

    // It's important to revoke the object URL after use to avoid memory leaks
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  // ------------------------------------------------
  // Functions (Retrieving Message Previews)
  // ------------------------------------------------

  async function refreshInboxMessages(amount: number = messageCountPerCall) {
    if (!inboxFolderId.value) return
    loadingMessagePreviews.value = true
    const { data, error } = await api.email.getMessagePreviews(inboxFolderId.value, amount)
    if (!error && data) inboxMessages.value = data
    loadingMessagePreviews.value = false
  }

  async function refreshUnreadInboxMessages(amount: number = messageCountPerCall) {
    if (!inboxFolderId.value) return
    loadingMessagePreviews.value = true
    const { data } = await api.email.getMessagePreviews(inboxFolderId.value, amount, 0, '&$filter=isRead eq false')
    if (!data) return
    unreadInboxMessages.value = data

    // if viewing a read message among unread inbox messages, insert selected message back into unreadInboxMessages list at the correct index
    if (!selectedMessage.value || !selectedMessage.value.isRead) return
    // if currently viewing unread inbox messages, insert selected message back into the unreadInboxMessages list at the correct index
    const messagePreview = new EmailMessagePreview(selectedMessage.value)

    if (viewingUnreadInboxMessages.value) {
      const index = unreadInboxMessages.value.findIndex(message => new Date(message.sentDateTime) < new Date(selectedMessage.value?.sentDateTime || ''))
      if (index >= 0) unreadInboxMessages.value.splice(index, 0, messagePreview) // catch if selected message is the oldest in the list
      else unreadInboxMessages.value.push(messagePreview)
    }
    // if viewing unread messages, with oldest on top, insert selected message back into otherMessages list at the correct index
    if (selectedFiltering.value === FilterMethod.Unread && selectedSort.value === SortDirection.Ascending) {
      otherMessages.value = sortMessagesBySentDateAscending(data)
      const index = otherMessages.value.findIndex(message => new Date(message.sentDateTime) > new Date(selectedMessage.value?.sentDateTime || ''))
      if (index >= 0) otherMessages.value.splice(index, 0, messagePreview) // catch if selected message is the newest in the list
      else otherMessages.value.push(messagePreview)
    }
    loadingMessagePreviews.value = false
  }

  async function refreshCurrentFolderMessages() {
    if (!selectedFolderId.value) return
    selectedFiltering.value = FilterMethod.All
    selectedSort.value = SortDirection.Descending
    getMessagePreviews(1)
  }

  function addMessagePreviewsToList(messagesToAdd: EmailMessagePreview[]) {
    if (viewingInboxMessages.value) {
      // First, remove any messages in inboxMessages that exist in messagesToAdd (this prevents duplicates)
      inboxMessages.value = inboxMessages.value.filter(message => !messagesToAdd.find(m => m.id === message.id))
      inboxMessages.value = [...inboxMessages.value, ...messagesToAdd]
      loadingMessagePreviews.value = false
    } else if (viewingUnreadInboxMessages.value) {
      unreadInboxMessages.value = unreadInboxMessages.value.filter(message => !messagesToAdd.find(m => m.id === message.id))
      unreadInboxMessages.value = [...unreadInboxMessages.value, ...messagesToAdd]
      loadingMessagePreviews.value = false
    } else {
      otherMessages.value = otherMessages.value.filter(message => !messagesToAdd.find(m => m.id === message.id))
      otherMessages.value = [...otherMessages.value, ...messagesToAdd]
      loadingMessagePreviews.value = false
    }
  }

  /** Retrieves messages and returns true if the QInfiniteScroll should stop (i.e., no more messages left or error) */
  async function getMessagePreviews(pageIndex: number): Promise<boolean> {
    loadingMessagePreviews.value = true

    const sortQueryString = getSortQuery(selectedSort.value, selectedFiltering.value === 'unread', selectedFolder.value?.displayName === 'Drafts')
    let amountToSkip = 0
    if (viewingInboxMessages.value) amountToSkip = inboxMessages.value.length
    else if (viewingUnreadInboxMessages.value) amountToSkip = unreadInboxMessages.value.length
    else amountToSkip = otherMessages.value.length

    // on first call
    if (pageIndex === 1) {
      // Get cached inbox messages
      if (viewingInboxMessages.value && inboxMessages.value.length > 0) {
        loadingMessagePreviews.value = false
        return false
      }
      if (viewingUnreadInboxMessages.value && unreadInboxMessages.value.length > 0) {
        loadingMessagePreviews.value = false
        return false
      }
    }

    // Get tagged messages
    if (selectedFiltering.value === FilterMethod.Tagged && selectedTagName.value && deletedFolderId.value) {
      const { data, error } = await api.email.getMessagePreviewsByTag(selectedTagName.value, deletedFolderId.value, messageCountPerCall, amountToSkip)
      if (data && !error) {
        addMessagePreviewsToList(data)
        return !!(data.length === 0)
      }
    }

    // Get search results
    if (selectedFiltering.value === FilterMethod.Search && searchText.value && searchIn.value) {
      const options: SearchMessagesOptions = {
        nextLink: searchPaginationUrl.value,
        searchText: searchText.value,
        amountToGet: messageCountPerCall,
        amountToSkip
      }
      let response
      if (searchIn.value === SearchIn.CurrentFolder && selectedFolderId.value) response = await api.email.searchMessagesByFolder(selectedFolderId.value, options)
      else response = await api.email.searchAllMessages(options) // Search in all folders
      if (response && response.value) {
        addMessagePreviewsToList(response.value)
        searchPaginationUrl.value = response['@odata.nextLink'] // save @odata.nextLink if it exists
        return !response['@odata.nextLink']
      }
    }

    // Get messages for selected folder
    if (selectedFolderId.value && selectedFiltering.value !== 'tagged' && selectedFiltering.value !== 'search') {
      // Get all flagged messages in selected folder
      if (selectedFiltering.value === FilterMethod.Flagged) {
        const { data, error } = await api.email.getAllFlaggedMessagePreviewsInFolder(selectedFolderId.value)
        if (data && !error) addMessagePreviewsToList(sortFlaggedMessages(data))
        return true
      }
      else {
        const { data, error } = await api.email.getMessagePreviews(selectedFolderId.value, messageCountPerCall, amountToSkip, sortQueryString)
        if (data && !error) {
          addMessagePreviewsToList(data)
          return !!(data.length === 0)
        }
      }
    }
    return true // something didn't match, stop infinite scroll
  }
  return {
    // ------------------------------------------------
    // Constants
    // ------------------------------------------------

    projectTagPrefix,
    searchInOptions,
    messageCountPerCall,

    // ------------------------------------------------
    // State
    // ------------------------------------------------

    // General
    initialRefreshCompleted,
    markAsReadAfterSeconds,
    enableConversationMode,
    unreadCountInbox,
    inboxFolderId,
    deletedFolderId,

    // Loading
    loading,
    loadingMessagePreviews,
    loadingMessageId,
    loadingSubFolderId,
    updatingMessageId,

    // Messages
    inboxMessages,
    unreadInboxMessages,
    otherMessages,
    messages,

    // Selected
    selectedFolderId,
    selectedMessage,
    multiSelectedMessages,
    selectedTagName,
    isInboxSelected,
    viewingInboxMessages,
    viewingUnreadInboxMessages,
    selectedTagId,
    selectedFolder,
    signatureIdToAddToEmailMessage,

    // Filter/Sort/Search
    selectedFiltering,
    selectedSort,
    searchText,
    submittedSearchText,
    searchIn,
    searchPaginationUrl,

    // Lists
    emailTags,
    allFolders,
    mainFolders,
    otherFolders,
    signatures,
    projectTags,

    // Composing/Sending
    isComposing,
    isComposedMessageModified,
    sendMethod,

    // ------------------------------------------------
    // Functions
    // ------------------------------------------------

    // General
    initialRefresh,
    reset,
    createNewMessage,
    selectMessage,
    setSelectedMessageReadStatus,
    refreshUnreadCountInbox,
    handleIncomingNotification,

    // Search
    resetSearch,
    search,

    // Selecting
    selectFolderAndGetMessages,
    filterByTagged,
    filterMessagesBy,
    sortMessagesBy,

    // Sending Messages / Saving Drafts
    sendMessage,
    saveDraft,

    // Deleting, Archiving, Moving Messages
    deleteSelectedMessage,
    archiveSelectedMessage,
    moveSelectedMessageToFolder,

    // Flagging Messages
    markSelectedMessageFlagComplete,
    flagSelectedMessage,

    // Folders
    setInboxAsSelected,
    refreshFolders,
    findFolderById,
    findFolderByIdRecursively,
    addFolderToList,
    removeFolderFromList,

    // Updating Selected Message
    updateEventResponseStatus,

    // Retrieving Message Previews
    refreshInboxMessages,
    refreshCurrentFolderMessages,
    getMessagePreviews,

    // Project Tags
    getProjectTags,
    addProjectTag,
    removeProjectTag,
    // Tags
    getEmailTags,
    removeTagFromSelectedMessage,
    clearTagsFromSelectedMessage,

    // Email Attachments
    getEmailAttachmentAsBlob,
    downloadEmailAttachment
  }
})
