import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from 'src/stores/user'
import { useQuasar } from 'quasar'
import { Notification, NotificationStatus } from 'src/types'
import * as api from 'src/api'
import { supabase } from 'src/supabase'
import { QueryOptions } from 'src/api/_EntityAPI'

export const useNotificationStore = defineStore('notifications', () => {
  const user = useUserStore()
  const $q = useQuasar()
  const notifications: Ref<Notification[]> = ref([])

  async function refreshNotifications() {
    if (!user.id) return
    const queryOptions: QueryOptions = {
      sortBy: 'created_at',
      sortDescending: true
    }
    const { data, error } = await api.notifications.getAll(queryOptions)
    if (!error) notifications.value = data as Notification[]
  }

  function listenForNotifications() {
    if (!user.activeTenant) return
    const channel = supabase.channel('notifications')

    // Handle the INSERT event
    channel.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'notifications_' + user.activeTenant.code }, payload => {
      $q.notify({
        message: `New notification item: ${payload.new.type}`,
        color: 'accent',
        icon: 'add',
        position: 'top'
      })
      notifications.value.push(payload.new as Notification)
      sortNotifications()
    })

    // Handle the DELETE event
    channel.on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'notifications_' + user.activeTenant.code }, payload => {
      const index = notifications.value.findIndex(notification => notification.id === payload.old.id)
      if (index !== -1) {
        notifications.value.splice(index, 1)
        sortNotifications()
      }
    })

    function sortNotifications() {
      notifications.value.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      })
    }

    channel.subscribe()
  }
  const count = computed(() => notifications.value.length)
  const unreadCount = computed(() => notifications.value.filter(n => n.status === NotificationStatus.Unread).length)

  return {
    notifications,
    count,
    unreadCount,
    refreshNotifications,
    listenForNotifications
  }
})
