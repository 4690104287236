import { supabase } from 'src/supabase'
import { FileObject, StorageError } from '@supabase/storage-js'
import { useUserStore } from 'src/stores/user'

export async function list(bucket: string, folder: string, sortBy?: string): Promise<{ data: FileObject[] | null, error: StorageError | null }> {
  const listOptions: {
    offset: number,
    sortBy?: { column: string, order: string }
  } = { offset: 0 }
  if (sortBy) {
    listOptions.sortBy = { column: sortBy, order: 'asc' }
  }
  return await supabase.storage.from(bucket).list(folder, listOptions)
}

export async function downloadImage(bucket: string, folder: string, fileName: string): Promise<{ data?: Blob, error?: { message: string } }> {
  if (fileName === '.emptyFolderPlaceholder') return {}
  const user = useUserStore()
  if (!user.activeTenantId) return { error: { message: 'No active tenant' } }
  const { data, error } = await supabase.storage.from(user.activeTenantId).download(bucket + '/' + (folder ? folder + '/' + fileName : fileName))
  if (!error && data) return { data }
  else return { error }
}

export async function downloadFile(bucket: string, folder: string, fileName: string): Promise<{ data?: Blob, error?: { message: string } }> {
  if (fileName === '.emptyFolderPlaceholder') return {}
  const user = useUserStore()
  if (!user.activeTenantId) return { error: { message: 'No active tenant' } }
  const { data, error } = await supabase.storage.from(user.activeTenantId).download(bucket + '/' + (folder !== '' ? folder + '/' + fileName : fileName))
  if (!error && data) return { data }
  else return { error }
}

export async function deleteFile(bucket: string, fileName: string, folder?: string | null) {
  const user = useUserStore()
  if (!user.activeTenantId) return { data: undefined, error: { message: 'No active tenant' } }
  return await supabase.storage.from(user.activeTenantId).remove([(folder ? folder + '/' + fileName : fileName)])
}

export async function deleteFiles(bucket: string, fileLocations: string[]) {
  const user = useUserStore()
  if (!user.activeTenantId) return { error: { message: 'No active tenant' } }
  fileLocations = fileLocations.map((fileLocation) => bucket + '/' + fileLocation)
  return await supabase.storage.from(user.activeTenantId).remove(fileLocations)
}
/*

export async function getSignature(userId: string): Promise<{ data?: string, error?: StorageError }> {
  const signatureFileName = 'signature'
  const { data, error } = await list('profiles', userId)
  if (!error && data) {
    // Find any image file named "signature"
    const myRegex = new RegExp(signatureFileName + '.')
    const signature = data.find((file) => file.name.match(myRegex))
    if (signature) {
      // Download signature
      return await downloadImage('profiles', userId, signature.name)
    }
  } else if (error) return error
}
*/
