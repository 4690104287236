import { Recipient, ItemBody, Importance, FollowupFlag, DateTimeTimeZone, EmailFileAttachment, EmailNewFileAttachment, EventResource, EventLocation } from './index'

// Priority
export interface PriorityOption {
  name: string
  value: Importance
  icon?: string
  color?: string
}

export interface InternetMessageHeader {
  name: string,
  value: string
}

export interface EmailExtension {
  id: string
}

export interface MultiValueLegacyExtendedProperty {
  id: string,
  value: string[]
}

export interface SingleValueLegacyExtendedProperty {
  id: string,
  value: string
}

export interface EmailMessage {
  bccRecipients?: Recipient[];
  body: ItemBody;
  bodyPreview: string;
  categories: string[];
  ccRecipients: Recipient[];
  changeKey: string;
  conversationId: string;
  conversationIndex: string;
  createdDateTime: string;
  flag: FollowupFlag;
  from: Recipient;
  hasAttachments: boolean;
  id: string;
  importance: Importance;
  inferenceClassification: string;
  internetMessageHeaders?: InternetMessageHeader[];
  internetMessageId: string;
  isDeliveryReceiptRequested: boolean | null;
  isDraft: boolean;
  isRead: boolean;
  isReadReceiptRequested: boolean;
  lastModifiedDateTime: string;
  parentFolderId?: string;
  receivedDateTime: string;
  replyTo?: Recipient[];
  sender: Recipient;
  sentDateTime: string;
  subject: string;
  toRecipients: Recipient[];
  uniqueBody?: ItemBody;
  webLink: string;
  event?: EventResource;
  attachments: EmailFileAttachment[];
  extensions?: EmailExtension[];
  multiValueExtendedProperties?: MultiValueLegacyExtendedProperty[];
  singleValueExtendedProperties?: SingleValueLegacyExtendedProperty[];
  // for EmailEventMessages
  meetingMessageType?: 'none' | 'meetingRequest' | 'meetingCancelled' | 'meetingAccepted' | 'meetingTenativelyAccepted' | 'meetingDeclined';
  responseType?: 'none' | 'organizer' | 'tentativelyAccepted' | 'accepted' | 'declined' | 'notResponded'; // for others responding to your event
  startDateTime?: DateTimeTimeZone;
  endDateTime?: DateTimeTimeZone;
  previousStartDateTime?: DateTimeTimeZone;
  previousEndDateTime?: DateTimeTimeZone;
  previousLocation?: EventLocation;
  location?: EventLocation;
}

export class EmailMessagePreview {
  id: string
  subject: string
  from: Recipient
  sentDateTime: string
  isRead: boolean
  flag: FollowupFlag
  sender: Recipient
  bodyPreview: string
  importance: Importance
  hasAttachments: boolean
  attachments: EmailFileAttachment[]
  isDraft: boolean
  lastModifiedDateTime: string
  categories: string[]
  meetingMessageType?: 'none' | 'meetingRequest' | 'meetingCancelled' | 'meetingAccepted' | 'meetingTenativelyAccepted' | 'meetingDeclined'
  event?: EventResource
  internetMessageHeaders?: InternetMessageHeader[]
  conversationId?: string
  parentFolderId?: string
  toRecipients?: Recipient[]

  constructor(message: EmailMessage) {
    this.id = message.id
    this.subject = message.subject
    this.from = message.from
    this.sentDateTime = message.sentDateTime
    this.isRead = message.isRead
    this.flag = message.flag
    this.sender = message.sender
    this.bodyPreview = message.bodyPreview
    this.importance = message.importance
    this.hasAttachments = message.hasAttachments
    this.attachments = message.attachments
    this.isDraft = message.isDraft
    this.lastModifiedDateTime = message.lastModifiedDateTime
    this.categories = message.categories
    this.event = message.event
    this.meetingMessageType = message.meetingMessageType
    this.internetMessageHeaders = message.internetMessageHeaders
    this.conversationId = message.conversationId
    this.parentFolderId = message.parentFolderId
    this.toRecipients = message.toRecipients
  }
}

export class EmailMessageToSend {
  subject: string
  body: ItemBody
  toRecipients: Recipient[]
  ccRecipients: Recipient[]
  bccRecipients: Recipient[]
  importance: Importance
  attachments?: EmailNewFileAttachment[]

  constructor(args: { subject: string, body: string, toList: string[], ccList: string[], bccList: string[], priority: Importance, attachments?: EmailNewFileAttachment[] }) {
    this.subject = args.subject
    this.body = { content: args.body, contentType: 'HTML' }
    this.importance = args.priority

    this.toRecipients = args.toList.map((email) => {
      return { emailAddress: { address: email.trim() } }
    })
    this.ccRecipients = args.ccList.map((email) => {
      return { emailAddress: { address: email.trim() } }
    })
    this.bccRecipients = args.bccList.map((email) => {
      return { emailAddress: { address: email.trim() } }
    })
    this.attachments = args.attachments
  }
}

export class EmailMessageToReplyOrForward {
  subject: string
  toRecipients: Recipient[]
  ccRecipients: Recipient[]
  bccRecipients: Recipient[]
  importance: Importance
  attachments?: EmailNewFileAttachment[]

  constructor(message: Omit<EmailMessageToSend, 'body'>) {
    this.subject = message.subject
    this.importance = message.importance
    this.toRecipients = message.toRecipients.map((email) => {
      return { emailAddress: { address: email.emailAddress.address.trim() } }
    })
    this.ccRecipients = message.ccRecipients.map((email) => {
      return { emailAddress: { address: email.emailAddress.address.trim() } }
    })
    this.bccRecipients = message.bccRecipients.map((email) => {
      return { emailAddress: { address: email.emailAddress.address.trim() } }
    })
    this.attachments = message.attachments
  }
}
