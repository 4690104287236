<script setup lang="ts">
import { onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { supabase } from 'src/supabase'
import { useUserStore } from 'src/stores/user'
import { useEmailStore } from './stores/email' // this is causing some errors
import { useNotificationStore } from './stores/notifications'
import { useEmailNotificationsStore } from './stores/email-notifications'
import { useRouter } from 'vue-router'
import { useTodoStore } from './stores/todos'
import { useIndexedDBStore } from 'src/stores/indexedDB'
import * as api from 'src/api'

const $q = useQuasar()
const user = useUserStore()
const emailStore = useEmailStore()
const emailNotifications = useEmailNotificationsStore()
const router = useRouter()
const todoStore = useTodoStore()
const notificationStore = useNotificationStore()

function redirectBasedOnUser() {
  // User is logged in -> redirect to dashboard if the user is currently at a page that doesn't require authentication
  if (user.session && router.currentRoute.value.meta.auth === false && router.currentRoute.value.name !== 'reset-password') {
    router.push({ name: 'dashboard' })
  }
  // User is not logged in -> redirect to login if the user is currently at a page that requires authentication
  else if (!user.session && router.currentRoute.value.meta.auth === true) router.push({ name: 'login' })
}

supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth event:', event)
  switch (event) {
    case 'SIGNED_IN':
      user.setFromSession(session)
      redirectBasedOnUser()
      break
    case 'SIGNED_OUT':
      user.clear()
      redirectBasedOnUser()
      break
    case 'TOKEN_REFRESHED':
      user.setFromSession(session)
      break
    case 'USER_UPDATED':
      user.setFromSession(session)
      break
    case 'PASSWORD_RECOVERY':
      // user.isResettingPassword = true
      if (router.currentRoute.value.name !== 'reset-password') router.push({ name: 'reset-password' })
      break
  }
})

onMounted(async () => {
  const indexedDBStore = useIndexedDBStore()
  await indexedDBStore.initializeDB()
  // Retrieve the user session from local storage
  console.log('getting session')
  const { data, error } = await supabase.auth.getSession()
  console.log('got session')
  // If the session is found
  if (!error && data && data.session) {
    // Set the user store data
    await user.setFromSession(data.session)
    // Set the main store data

    // mainStore.refreshAppData()
    // Set the email store data
    if (user.profile?.email && user.id) api.email.useGraphClient(user.profile.email, user?.id).then(emailStore.initialRefresh)
    // Listen for email notifications
    emailNotifications.listen(() => {
      $q.notify({
        message: 'New email received!',
        color: 'primary',
        icon: 'email',
        position: 'top',
        timeout: 5000
      })
    })
    // Refresh & listen for todos
    todoStore.refreshTodos()
    todoStore.listenForTodos()
    // Refresh & listen for notifications
    notificationStore.refreshNotifications()
    notificationStore.listenForNotifications()
  } else user.clear() // Session not found

  // Redirect based on the user session (if necessary)
  if (router.currentRoute.value.name !== 'reset-password') redirectBasedOnUser()
})
</script>

<template>
  <router-view />
</template>
