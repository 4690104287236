export class BaseInput {
  static entityName: string

  static validateRequiredFields(instance: object, requiredFields: Array<string>): boolean {
    for (const field of requiredFields) {
      if (instance[field as keyof object] === null || instance[field as keyof object] === undefined) {
        return false
      }
    }
    return true
  }

  static deepClone(obj: object): object {
    return JSON.parse(JSON.stringify(obj))
  }
}
