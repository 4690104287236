import { createClient } from '@supabase/supabase-js'
import { Database } from 'src/schema'

export const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
export const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

// export const archSupabaseUrl = import.meta.env.VITE_ARCH_SUPABASE_URL
// export const archSupabaseAnonKey = import.meta.env.VITE_ARCH_SUPABASE_ANON_KEY

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey)
// export const archSupabase = createClient<Database>(archSupabaseUrl, archSupabaseAnonKey)
