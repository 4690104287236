import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from 'src/stores/user'
import { useQuasar } from 'quasar'
import type { Todo } from 'src/types'
import * as api from 'src/api'
import { supabase } from 'src/supabase'

export const useTodoStore = defineStore('todos', () => {
  const user = useUserStore()
  const $q = useQuasar()
  const todos: Ref<Todo[]> = ref([])

  async function refreshTodos() {
    if (!user.id) return
    const { data, error } = await api.todos.getIncomplete(user.id)
    if (!error) todos.value = data as Todo[]
  }

  function listenForTodos() {
    if (!user.activeTenant) return
    const channel = supabase.channel(`public:todos:assigned_to=eq.${user.id}`)

    // Handle the INSERT event
    channel.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'todos_' + user.activeTenant.code }, payload => {
      if (payload.new.assigned_to !== user.id) return
      $q.notify({
        message: `New todo item: ${payload.new.type}`,
        color: 'accent',
        icon: 'add',
        position: 'top'
      })
      todos.value.push(payload.new as Todo)
      sortTodos()
    })

    // Handle the DELETE event
    channel.on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'todos_' + user.activeTenant.code }, payload => {
      const index = todos.value.findIndex(todo => todo.id === payload.old.id)
      if (index !== -1) {
        todos.value.splice(index, 1)
        sortTodos()
      }
    })

    // Helper function to sort todos based on due_date and created_at
    function sortTodos() {
      todos.value.sort((a, b) => {
        if (a.due_date && b.due_date) return a.due_date > b.due_date ? 1 : -1
        if (a.due_date) return -1
        if (b.due_date) return 1
        return a.created_at > b.created_at ? 1 : -1
      })
    }

    channel.subscribe()
  }
  const count = computed(() => todos.value.length)

  return {
    todos,
    count,
    refreshTodos,
    listenForTodos
  }
})
