// ========================================
// Channel Type
// ========================================
import { BaseInput, Nullable, PartialOrNull, INumberedEntity, ICreated } from 'src/types'

// ----------------------------------------
// Channel Fields
// ----------------------------------------

// Required fields
export type ChannelRequiredFields = {
  name: string
  owner_id: string
  is_public: boolean
  tenant_id: string
} & INumberedEntity & ICreated

// Required field names (for validation)
const requiredFields: Array<keyof ChannelRequiredFields> = [
  'name',
  'owner_id',
  'is_public',
  'tenant_id'
]

// Nullable fields
export type ChannelNullableFields = {
  project_id: number | null
  description: string | null
  type: string | null
}

// ----------------------------------------
// Channel Interface
// ----------------------------------------
export interface Channel extends ChannelRequiredFields, ChannelNullableFields { }

// ----------------------------------------
// Channel Input Class
// ----------------------------------------
type ChannelCreationFields = Omit<Nullable<ChannelRequiredFields & PartialOrNull<ChannelNullableFields>>, 'id' | 'created_at'> // required fields can be null for creation
type ChannelInputFields = PartialOrNull<ChannelCreationFields> // all fields can be optional for creation

export class ChannelInput extends BaseInput implements ChannelCreationFields {
  static requiredFields = requiredFields
  static entityName = 'Channel'

  name: string | null = null
  owner_id: string | null = null
  is_public: boolean | null = true
  project_id?: number | null
  description?: string | null
  type?: string | null
  tenant_id: string | null = null

  constructor(fields: ChannelInputFields = {}) {
    super()
    const clonedFields: ChannelInputFields = BaseInput.deepClone(fields) as ChannelInputFields
    // Remove any properties from fields that don't exist in Input type
    Object.keys(clonedFields).forEach((key) => { if (!(key in this)) delete clonedFields[key as keyof ChannelInputFields] })
    Object.assign(this, clonedFields)
  }

  static isValid(instance: ChannelInput): boolean {
    return this.validateRequiredFields(instance, this.requiredFields)
  }
}
