// ========================================
// ActiveSubscription Type
// ========================================
import { BaseInput, Nullable, PartialOrNull, INumberedEntity, ICreated } from 'src/types'

// ----------------------------------------
// ActiveSubscription Fields
// ----------------------------------------

// Required fields
export type ActiveSubscriptionRequiredFields = {
  user_id: string
  subscription_id: string
  resource: string
  expires_at: string
} & INumberedEntity & ICreated

// Required field names (for validation)
const requiredFields: Array<keyof ActiveSubscriptionRequiredFields> = [
  'user_id',
  'subscription_id',
  'resource'
]

// ----------------------------------------
// ActiveSubscription Interface
// ----------------------------------------
export interface ActiveSubscription extends ActiveSubscriptionRequiredFields { }

// ----------------------------------------
// ActiveSubscription Input Class
// ----------------------------------------
type ActiveSubscriptionCreationFields = Omit<Nullable<ActiveSubscriptionRequiredFields>, 'id' | 'created_at'> // required fields can be null for creation
type ActiveSubscriptionInputFields = PartialOrNull<ActiveSubscriptionCreationFields> // all fields can be optional for creation

export class ActiveSubscriptionInput extends BaseInput implements ActiveSubscriptionCreationFields {
  static requiredFields = requiredFields
  static entityName = 'ActiveSubscription'

  user_id: string | null = null
  subscription_id: string | null = null
  resource: string | null = null
  expires_at: string | null = null

  constructor(fields: ActiveSubscriptionInputFields = {}) {
    super()
    const clonedFields: ActiveSubscriptionInputFields = BaseInput.deepClone(fields) as ActiveSubscriptionInputFields
    // Remove any properties from fields that don't exist in Input type
    Object.keys(clonedFields).forEach((key) => { if (!(key in this)) delete clonedFields[key as keyof ActiveSubscriptionInputFields] })
    Object.assign(this, clonedFields)
  }

  static isValid(instance: ActiveSubscriptionInput): boolean {
    return this.validateRequiredFields(instance, this.requiredFields)
  }
}

// ----------------------------------------
// ActiveSubscription Type Aliases
// ----------------------------------------
export type ActiveSubInput = ActiveSubscriptionInput
export type ActiveSub = ActiveSubscription
