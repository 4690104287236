import { Client } from '@microsoft/microsoft-graph-client'
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import * as api from 'src/api'
// types
import { type AuthenticationResult, scopes, ActiveSubscriptionInput, ActiveSubscription, EmailCategory, EmailMessage, EmailFolder, EmailMessagePreview, EmailMessageToSend, EmailMessageToReplyOrForward } from 'src/types'
import { DistributionList, BatchRequest } from 'src/types/email'

let msal: PublicClientApplication | null = null
let graphClient: null | Client = null
let accessToken: string | null = null
let loginInProgress = false

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL

const redirectUrl = import.meta.env.VITE_APP_URL
const azureClientId = import.meta.env.VITE_AZURE_CLIENT_ID
const azureTenantId = import.meta.env.VITE_AZURE_TENANT_ID

export function logout() {
  if (msal) msal.logoutRedirect()
}

async function useMsal(): Promise<PublicClientApplication> {
  if (msal !== null) return msal
  const msalConfig = {
    auth: {
      clientId: azureClientId,
      authority: 'https://login.microsoftonline.com/' + azureTenantId,
      redirectUri: redirectUrl,
      postLogoutRedirectUri: redirectUrl
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  }
  msal = new PublicClientApplication(msalConfig)
  await msal.initialize()
  return msal
}

async function doSilentLogin(userEmail?: string) {
  const msal = await useMsal()
  const response: AuthenticationResult = await msal.ssoSilent({ loginHint: userEmail, scopes })
  if (response.accessToken) {
    saveTokenExpirationToLocalStorage(response.accessToken)
    accessToken = response.accessToken
    return accessToken
  }
  return null
}

async function doPopupLogin() {
  const msal = await useMsal()
  const response: AuthenticationResult = await msal.loginPopup({ scopes })
  if (response.accessToken) {
    saveTokenExpirationToLocalStorage(response.accessToken)
    accessToken = response.accessToken
    return accessToken
  }
  return null
}

function saveTokenExpirationToLocalStorage(token: string) {
  // Split the JWT into its three parts
  const parts = token.split('.')
  // Decode the payload (second part) from Base64Url encoding
  const payload = JSON.parse(atob(parts[1]))
  localStorage.setItem('MStokenExp', payload.exp)
}

function checkTokenExpiration(): boolean {
  const tokenExp = localStorage.getItem('MStokenExp')
  if (tokenExp) {
    const now = new Date()
    const expDate = new Date(tokenExp)
    if (expDate < now) {
      localStorage.removeItem('MStokenExp')
      return false
    }
  }
  return true
}

async function useAccessToken(userEmail?: string): Promise<string | null> {
  let tryingPopupLogin = false
  if (accessToken) return accessToken
  try {
    loginInProgress = true
    const silentSignInResult = await doSilentLogin(userEmail)
    if (silentSignInResult) return silentSignInResult
    else {
      tryingPopupLogin = true
      return await doPopupLogin()
    }
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) return await doPopupLogin()
    else if (!tryingPopupLogin) return await doPopupLogin()
    else throw new Error('Error authenticating with Microsoft')
  } finally {
    if (import.meta.env.MODE === 'development') loginInProgress = false
  }
}

export async function useGraphClient(userEmail?: string, userId?: string): Promise<Client | null> {
  const tokenUpToDate = checkTokenExpiration()
  if (!tokenUpToDate) graphClient = null
  // if token is still valid and we already have a graphClient, return it
  if (graphClient !== null) return graphClient
  // else create a new graphClient, token, etc
  if (!userEmail || !userId) return null
  if (loginInProgress) return null
  const token = accessToken || await useAccessToken(userEmail)
  if (!token) throw new Error('Error authenticating with Microsoft')
  graphClient = Client.init({
    authProvider: (done) => {
      done(null, token)
    }
    // defaultVersion: 'beta'
  })
  getAndRenewSubscriptions(userId)
  return graphClient
}

export async function getAndRenewSubscriptions(userId: string) {
  // Get current subscriptions (check if we already have a subscription for the user)
  const { data: subData, error: subError } = await api.activeSubscriptions.getAll()
  if (subData && !subError) {
    let emailSubscriptionExists = false
    // Find subscriptions for email inbox
    for (let i = 0; i < subData.length; i++) {
      if (subData[i].resource === "/me/mailFolders('inbox')/messages") {
        const now = new Date()
        const expiration = new Date(subData[i].expires_at)
        // If subscription is expired OR if we already have an email subscription, delete it
        if (expiration < now || emailSubscriptionExists) await api.activeSubscriptions.hardDelete(subData[i].id)
        // If subscription is not expired, renew it
        else {
          const response = await api.email.renewSubscription(subData[i].subscription_id)
          if (response && !response.error) {
            // Update subscription in supabase with new expiration date
            const newSub = new ActiveSubscriptionInput(subData[i])
            newSub.expires_at = response.expirationDateTime
            await api.activeSubscriptions.updateByConditions({ subscription_id: { operator: 'eq', value: newSub.subscription_id } }, newSub as Partial<ActiveSubscription>)
          }
          emailSubscriptionExists = true
        }
      }
    }
    if (!emailSubscriptionExists) {
      // Create subscription
      if (userId) {
        const createSubResult = await api.email.createSubscription(userId)
        if (createSubResult) {
          const newSub = new ActiveSubscriptionInput({ user_id: createSubResult.clientState, subscription_id: createSubResult.id, resource: '/me/mailFolders(\'inbox\')/messages', expires_at: createSubResult.expirationDateTime })
          await api.activeSubscriptions.add(newSub)
        }
      }
    }
  }
}

export async function createSubscription(userId: string) {
  function getNextDayTimestamp(): string {
    const currentDate = new Date()
    const nextDay = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
    return nextDay.toISOString()
  }
  const client = await useGraphClient()
  if (!client) throw new Error('Graph client not initialized')
  const subscription = await client.api('/subscriptions').post({
    changeType: 'created',
    notificationUrl: supabaseUrl + '/functions/v1/handle_email_notifications',
    resource: '/me/mailFolders(\'inbox\')/messages',
    // includeResourceData: true,
    // encryptionCertificate: 'MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBhhR5HzkHGyVzUOXFMwfjgGMif6xlSF0i6R9R1itK5jeMhBW8UPG8exn23InF2UYDup0KBK8yC/YsepcJSqJbDSTl3NGOELv/l7u6QuP/wTJxT3SFa9rjrs0Sq/84BkQ23e8k0EyBC1nYvj8I79E9MqIkVg/imzJ/+KHavUbahTAZ/9m1yaqwN5eMxymhwMgYT3rfCU9ioAJT7NCdq3QVtIZiayASzhiRZ2vXCoY3MD7vpxNbVE8nIw75PGIVc9k2rYQbRl08hSNLgRn462+390b/6+6fTHuiw9tHZaNcWId66t28jBb12aqEyVW4PzcG8UkY6k9EDhX4DAtm8u8JZAgMBAAE=',
    // encryptionCertificateId: 'testCertificateId',
    expirationDateTime: getNextDayTimestamp(), // webhook subscription expiration time (max: 3 days)
    clientState: userId // required (secret used to confirm that notifications originate from Microsoft Graph)
  })
  return subscription
}

export async function renewSubscription(subscriptionId: string) {
  function getNextDayTimestamp(): string {
    const currentDate = new Date()
    const nextDay = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
    return nextDay.toISOString()
  }
  const client = await useGraphClient()
  if (!client) throw new Error('Graph client not initialized')
  // refresh subscription with Microsoft Graph
  const response = await client.api(`/subscriptions/${subscriptionId}`).patch({
    expirationDateTime: getNextDayTimestamp()
  })
  if (response && response.id) return response
}

export async function deleteSubscription(subscriptionId: string) {
  const client = await useGraphClient()
  if (!client) throw new Error('Graph client not initialized')
  return await client.api(`/subscriptions/${subscriptionId}`).delete()
}

export async function listSubscriptions() {
  const client = await useGraphClient()
  if (!client) throw new Error('Graph client not initialized')
  return await client.api('/subscriptions').get()
}

export async function deleteAnyMSEmailSubscriptions() {
  const subscriptions = await listSubscriptions()
  if (subscriptions && subscriptions.value) {
    for (const sub of subscriptions.value) {
      if (sub.resource === '/me/mailFolders(\'inbox\')/messages') {
        await deleteSubscription(sub.id)
      }
    }
  }
}

// ================================  API Call Handler ================================

async function apiCall(url: string, method = 'get', dataObject?: object) {
  const client = await useGraphClient()
  if (!client) throw new Error('Graph client not initialized')
  try {
    let response
    switch (method) {
      case 'update':
        response = await client.api(url).update(dataObject)
        break
      case 'post':
        response = await client.api(url).post(dataObject)
        break
      case 'delete':
        response = await client.api(url).delete()
        break
      default:
        response = await client.api(url).get()
        break
    }

    // sometimes the response contains a value property, sometimes it doesn't, sometimes there is no body at all
    if (response && response.value) return { data: response.value }
    else if (response && !response.value) return { data: response }
    else return { error: 'No response' }
  } catch (error) {
    return { error: typeof error === 'string' ? error : 'Unknown Error' }
  }
}

// =================================   Batch Request Handler   =======================================

/** Send many requests at the same time. This is a recursive function that will call itself until all requests are processed.
 *  @param requests An array of BatchRequest objects. Each request object must have an id, method, and url property.
 *  @returns An array of all the results. Each result is the response body of the corresponding request. If there was an error, the result will be null.
 */
export async function sendBatchRequest(requests: BatchRequest[], allResults = []) {
  // you can only send a batch of up to 20 requests at a time. So I recursively call this function until all requests are sent
  const { data, error } = await apiCall('/$batch', 'post', { requests: requests.slice(0, 20) })
  if (error) return allResults

  const responseData = data.responses.map((response: unknown) => {
    if (response.status === 200) return response.body
    else return null
  })
  allResults.push(...responseData)

  // Check if there are more requests to process
  if (requests.length > 20) {
    // Recursively call sendBatchRequest with the remaining items
    const nextBatchInfos = requests.splice(20)
    await sendBatchRequest(nextBatchInfos, allResults)
  }
  // all results are consolidated into one array and returned
  return allResults
}

// ========================================   Folders   ==============================================

/** Returns all folders. These are PARENT folders only */
export async function getAllFolders(): Promise<{ data?: EmailFolder[], error?: string }> {
  const { data, error } = await apiCall('/me/mailFolders/$count') // get the total number of primary folders first
  if (!error && data) return await apiCall(`/me/mailFolders?$top=${data}`)
  else return { error }
}

/** Retrieves ALL child folders for the folder provided */
export async function getChildFolders(folderId: number | string): Promise<{ data?: EmailFolder[], error?: string }> {
  // get the total number of child folders first
  const { data } = await apiCall(`/me/mailFolders/${folderId}/childFolders/$count`)
  let url = `/me/mailFolders/${folderId}/childFolders`
  if (data) url = url + `?$top=${data}` // use the number of child folders to get them all at once
  return await apiCall(url)
}

export async function getUnreadCount(folderId: string | number): Promise<{ data?: number, error?: string }> {
  const result = await apiCall(`/me/mailFolders/${folderId}`)
  if (result.data && typeof result.data.unreadItemCount === 'number') {
    return { data: result.data.unreadItemCount }
  } else {
    return { error: 'Could not retrieve unread count' }
  }
}

export async function createFolder(folderName: string): Promise<{ data?: EmailFolder, error?: string }> {
  return await apiCall('/me/mailFolders', 'post', { displayName: folderName })
}

export async function createChildFolder(folderName: string, parentFolderId: number | string): Promise<{ data?: EmailFolder, error?: string }> {
  return await apiCall(`/me/mailFolders/${parentFolderId}/childFolders`, 'post', { displayName: folderName })
}

export async function updateFolder(folderId: number | string, folderUpdates: Partial<EmailFolder>): Promise<{ data?: EmailFolder, error?: string }> {
  return await apiCall(`/me/mailFolders/${folderId}`, 'update', folderUpdates)
}

/** Moves folder to "Deleted Items" as a subfolder by default. You have to delete it from there to permanently delete it
*   @return nothing if successful, error if not
*/
export async function deleteFolder(folderId: number | string): Promise<{ error?: string }> {
  return await apiCall(`/me/mailFolders/${folderId}`, 'delete')
}

// ========================================   GET Messages  ==============================================

const previewSelectQuery = '&$select=toRecipients,parentFolderId,subject,from,sentDateTime,isRead,flag,sender,bodyPreview,importance,isDraft,hasAttachments,categories,lastModifiedDateTime,conversationId,microsoft.graph.eventMessage/meetingMessageType,internetMessageHeaders'
const previewExpandQuery = '&$expand=attachments($select=id,name,contentType,size),microsoft.graph.eventMessage/event($select=responseStatus)'
const PREVIEW_PARAM_QUERY = previewSelectQuery + previewExpandQuery

/** Returns specified amount of message previews for the specified folder. Supports pagination.
 *  @param folderId The ID of the folder to retrieve messages from
 *  @param amountToGet Num of messages you want to retrieve
 *  @param amountToSkip Num of messages you want to skip (Defaults to 0). For pagination
 *  @param additionalQueryParams Any additional query parameters you want to add. Ex: `&$filter=importance eq 'high'`
 */
export async function getMessagePreviews(folderId: number | string, amountToGet: number, amountToSkip: number = 0, additionalQueryParams?: string): Promise<{ data?: EmailMessagePreview[], error?: string }> {
  let url = `/me/mailFolders/${folderId}/messages?${PREVIEW_PARAM_QUERY}&$top=${amountToGet}&$skip=${amountToSkip || 0}`
  if (additionalQueryParams) url += additionalQueryParams
  return await apiCall(url)
}

export async function getConversationMessagePreviews(conversationId: number | string, amountToGet: number, amountToSkip = 0): Promise<{ data?: EmailMessagePreview[], error?: string }> {
  const url = `/me/messages?$filter=conversationId eq '${conversationId}'${PREVIEW_PARAM_QUERY}&$top=${amountToGet}&$skip=${amountToSkip}`
  return await apiCall(url)
}

/** We get flagged messages with a separate query. We have to manually sort them by dueDate so we need all of the flagged messages at once. */
export async function getAllFlaggedMessagePreviewsInFolder(folderId: number | string): Promise<{ data?: EmailMessagePreview[], error?: string }> {
  let url = `/me/mailFolders/${folderId}/messages?$filter=flag/flagStatus eq 'flagged'${PREVIEW_PARAM_QUERY}`

  const countResponse = await apiCall(`/me/mailFolders/${folderId}/messages/$count?$filter=flag/flagStatus eq 'flagged'`)
  url = url + `&$top=${countResponse.data}` // get ALL non complete flagged messages

  return await apiCall(url)
}

/** Get messages by tag aka "category" displayName.
 *
 *  As of now, I cant filter MS messages by category id. Here is an article outlining the method I'm using here. https://learn.microsoft.com/en-us/answers/questions/666330/microsoft-graph-api-filtering-on-categories
 */
export async function getMessagePreviewsByTag(tagName: string, deletedFolderId: string, amountToGet: number, amountToSkip = 0): Promise<{ data?: EmailMessagePreview[], error?: string }> {
  const url = `/me/messages?$filter=categories/any(a:a eq '${tagName}') and parentFolderId ne '${deletedFolderId}'&$top=${amountToGet}${PREVIEW_PARAM_QUERY}&$skip=${amountToSkip}`
  return await apiCall(url)
}

export async function getFullMessage(messageId: number | string): Promise<{ data?: EmailMessage, error?: string }> {
  return await apiCall(`/me/messages/${messageId}?&$expand=attachments($select=id,name,contentType,size, isInline),microsoft.graph.eventMessage/event`)
}

// ========================================   Modify Messages   ==============================================

/** See https://learn.microsoft.com/en-us/graph/api/message-update?view=graph-rest-1.0&tabs=http#request-body
 *
 *  Updates a message using the message Id. You provide a JSON object with properties and objects you wish to update.
 *  @param messageId The ID of the message to update
 *  @param messageUpdates The JSON object containing the properties and objects you wish to update
 */
export async function updateMessage(messageId: number | string, messageUpdates: Partial<EmailMessage> | EmailMessageToSend) {
  const url = `/me/messages/${messageId}`
  return await apiCall(url, 'update', messageUpdates)
}

/** See: https://learn.microsoft.com/en-us/graph/api/message-move?view=graph-rest-1.0&tabs=http
 *
 *  Moves a message to a specified folder.
 *  @param graphClient The graph client object
 *  @param messageId The ID of the message to move
 *  @param destinationFolderId The ID of the destination folder or the name of a common mailbox like 'deletedItems'
 */
export async function moveMessage(messageId: number | string, destinationFolderId: string) {
  const url = `/me/messages/${messageId}/move`
  return await apiCall(url, 'post', { destinationId: destinationFolderId })
}

export async function batchMoveMessages(messages: EmailMessagePreview[], destinationFolderId: string) {
  if (messages.length === 0) return

  const batchRequests: BatchRequest[] = []
  for (let i = 0; i < messages.length; i++) {
    batchRequests.push(new BatchRequest(i + 1, 'POST', `/me/messages/${messages[i].id}/move`, { destinationId: destinationFolderId }))
  }
  return await sendBatchRequest(batchRequests)
}

// ========================================   Deleting Messages   ==============================================

/** Moves message to "Deleted Items" folder */
export async function softDeleteMessage(messageId: number | string) {
  const url = `/me/messages/${messageId}/move`
  return await apiCall(url, 'post', { destinationId: 'deletedItems' })
}

/** Permanently deletes from server */
export async function hardDeleteMessage(messageId: number | string) {
  const url = `/me/messages/${messageId}`
  return await apiCall(url, 'delete')
}

/** Batch soft/hard delete messages depending on the messages parentFolderId */
export async function batchDeleteMessages(messages: EmailMessagePreview[], deletedItemsFolderId?: string) {
  if (messages.length === 0) return

  const batchRequests: BatchRequest[] = []
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].parentFolderId === deletedItemsFolderId) batchRequests.push(new BatchRequest(i + 1, 'DELETE', `/me/messages/${messages[i].id}`))
    else batchRequests.push(new BatchRequest(i + 1, 'POST', `/me/messages/${messages[i].id}/move`, { destinationId: 'deletedItems' }))
  }
  return await sendBatchRequest(batchRequests)
}

// ======================================   Send/Respond to Messages   ==============================================

export async function sendNewEmailMessage(messageContent: EmailMessageToSend) {
  return await apiCall('/me/sendMail', 'post', { message: messageContent })
}

/** This endpoint is only for drafts that need to be sent. The message will be sent as it was saved. You need to update a draft separately if any adjustments were made. */
export async function sendDraftMessage(messageId: number | string) {
  return await apiCall(`/me/messages/${messageId}/send`, 'post')
}

/** Reply to Message. Each time we must provide the entire message object as we want it sent since we are not using the "comment" property.
 *  @param messageId The ID of the message to reply to
 *  @param messageContent The message object with to/cc/bcc and other properties. We explicitly generate and include the "message" property to allow for full customization
 */
export async function replyToEmailMessage(messageId: number | string, messageContent: EmailMessageToSend) {
  return await apiCall(`/me/messages/${messageId}/reply`, 'post', { message: messageContent })
}

/** Forward Message. Each time we must provide the entire message object as we want it sent since we are not using the "comment" property.
 *  @param messageId The ID of the message to forward
 *  @param messageContent The message object with to/cc/bcc and other properties. We explicitly generate and include the "message" property to allow for full customization
 *
 * See: https://learn.microsoft.com/en-us/graph/api/message-forward?view=graph-rest-1.0&tabs=http#:~:text=Specify%20either%20a%20comment%20or%20the%20body%20property%20of,neither%20will%20return%20an%20HTTP%20400%20Bad%20Request%20error.
 */
export async function forwardEmailMessage(messageId: number | string, messageContent: EmailMessageToSend) {
  return await apiCall(`/me/messages/${messageId}/forward`, 'post', { message: messageContent })
}

// =======================================   Creating Draft Messages   ================================================

export async function createDraft(messageContent: EmailMessageToSend) {
  return await apiCall('/me/messages', 'post', messageContent)
}

/** Saves reply drafts. We use the comment property vs message.body.content property to prevent manipulation of the previous message chain */
export async function createReplyDraft(messageId: number | string, messageContent: EmailMessageToSend) {
  const messageWithoutBody = new EmailMessageToReplyOrForward(messageContent)
  return await apiCall(`/me/messages/${messageId}/createReply`, 'post', { message: messageWithoutBody, comment: messageContent.body.content })
}

export async function createForwardDraft(messageId: number | string, messageContent: EmailMessageToSend) {
  const messageWithoutBody = new EmailMessageToReplyOrForward(messageContent)
  return await apiCall(`/me/messages/${messageId}/createForward`, 'post', { message: messageWithoutBody, comment: messageContent.body.content })
}

// ========================================   Attachments   =============================================

export async function getAttachment(messageId: number | string, attachmentId: number | string) {
  const url = `/me/messages/${messageId}/attachments/${attachmentId}/$value`
  return await apiCall(url)
}

export async function uploadAttachment(attachmentName: string /*, attachmentContent?: string, messageId?: number | string */) {
  if (!graphClient) return
  const uploadSession = await graphClient.api('/me/drive/root:/' + attachmentName + ':/createUploadSession').post({
    item: {
      // if name conflict, rename the new file. Other option is 'fail', which throws an error or 'replace', which replaces the old file
      '@microsoft.graph.conflictBehavior': 'rename',
      name: attachmentName
    }
  })
  return uploadSession
}

export async function getBatchEmailAttachments(messageId: string, attachmentInfos: { id: string }[]) {
  if (!attachmentInfos || attachmentInfos.length === 0) return []

  const batchRequests: BatchRequest[] = []
  // Create the batch requests
  for (let i = 0; i < attachmentInfos.length; i++) {
    const request = new BatchRequest(i + 1, 'GET', `/me/messages/${messageId}/attachments/${attachmentInfos[i].id}`)
    batchRequests.push(request)
  }
  return await sendBatchRequest(batchRequests)
}

// =====================================  Categories AKA "Tags"   ==========================================

export async function getAllTags(): Promise<{ data?: EmailCategory[], error?: string }> {
  return await apiCall('/me/outlook/masterCategories')
}

export async function createTag(tagName: string): Promise<{ data?: EmailCategory, error?: string }> {
  return await apiCall('/me/outlook/masterCategories', 'post', { displayName: tagName })
}

export async function updateTagList(messageId: number | string, tags: string[]): Promise<{ data?: EmailMessage, error?: string }> {
  return updateMessage(messageId, { categories: tags })
}

export async function deleteTag(tagId: string): Promise<{ data?: string, error?: string }> {
  return await apiCall(`/me/outlook/masterCategories/${tagId}`, 'delete')
}

// =============================================  Events   ==================================================

export async function acceptEvent(eventId: number | string, notifyOrganizer: boolean, comment?: string): Promise<{ data?: EmailMessage, error?: string }> {
  if (comment && comment?.length > 0) return await apiCall(`/me/events/${eventId}/accept`, 'post', { sendResponse: true, comment })
  else return await apiCall(`/me/events/${eventId}/accept`, 'post', { sendResponse: notifyOrganizer })
}

export async function tentativelyAcceptEvent(eventId: number | string, notifyOrganizer: boolean, comment?: string): Promise<{ data?: EmailMessage, error?: string }> {
  if (comment && comment?.length > 0) return await apiCall(`/me/events/${eventId}/tentativelyAccept`, 'post', { sendResponse: true, comment })
  else return await apiCall(`/me/events/${eventId}/tentativelyAccept`, 'post', { sendResponse: notifyOrganizer })
}

export async function declineEvent(eventId: number | string, notifyOrganizer: boolean, comment?: string): Promise<{ data?: EmailMessage, error?: string }> {
  if (comment && comment?.length > 0) return await apiCall(`/me/events/${eventId}/decline`, 'post', { sendResponse: true, comment })
  else return await apiCall(`/me/events/${eventId}/decline`, 'post', { sendResponse: notifyOrganizer })
}

// =============================================  Searching Messages   ==================================================

/** Custom API call for search results. Since we cannot use $skip to paginate, we need to collect the "@odata.nextLink" url if it exists in the response.
*   Therefore, we are not using apiCall() here. I created apiSearch() to allow for that customization.
*
*   We are saving the nextLink url in emailStore and using it to retrieve the next page of results.
*   @param url The url to search. This can be a nextLink url or a custom search url created by searchAllMessages() or searchMessagesByFolder()
*/
export async function apiSearch(url: string) {
  const client = await useGraphClient()
  if (!client) throw new Error('Graph client not initialized')

  try {
    const response = await client.api(url).get()
    if (response) return response
    else return { error: 'No response' }
  } catch (error) {
    return { error: typeof error === 'string' ? error : 'Unknown Error' }
  }
}

export interface SearchMessagesOptions {
  nextLink: string | null; // allows us to pass the "nextLink" url for pagination
  searchText: string;
  amountToGet: number;
  amountToSkip?: number; // Make amountToSkip optional with a default value
}

/** Info on search query parameters - https://learn.microsoft.com/en-us/graph/search-query-parameter?context=graph%2Fapi%2F1.0&view=graph-rest-1.0&tabs=http */
export async function searchAllMessages(searchOptions: SearchMessagesOptions) {
  const { nextLink, searchText, amountToGet, amountToSkip = 0 } = searchOptions
  // we have to use nextLink for pagination because the $skip parameter does not work with $search
  const url = (nextLink) || `/me/messages?$search="${searchText}"&$top=${amountToGet}&$from=${amountToSkip}${PREVIEW_PARAM_QUERY}`
  return await apiSearch(url)
}

export async function searchMessagesByFolder(folderId: number | string, searchOptions: SearchMessagesOptions): Promise<{ data?: EmailMessagePreview[], error?: string }> {
  const { nextLink, searchText, amountToGet, amountToSkip = 0 } = searchOptions
  // we have to use nextLink for pagination because the $skip parameter does not work with $search
  const url = (nextLink) || `/me/mailFolders/${folderId}/messages?$search="${searchText}"&$top=${amountToGet}${PREVIEW_PARAM_QUERY}&$from=${amountToSkip}`
  return await apiSearch(url)
}

/** Retrieve "Mailing Lists" or "Distribution Lists" */
export async function getDistributionLists() {
  // Distribution Lists are just "Groups" but they can be isolated with securityEnabled eq false, mailEnabled eq true and groupTypes = []
  const url = '/groups?$filter=mailEnabled eq true and securityEnabled eq false&$select=id,displayName,groupTypes,mailEnabled,mail,securityEnabled'
  const { data, error } = await apiCall(url)
  if (data) {
    const results = data.filter((dl: DistributionList) => dl.groupTypes.length === 0) // the last filter needed to isolate distribution lists
    return { data: results }
  }
  return { error }
}
