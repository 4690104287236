// --------------------------------------
// Email Enums
// --------------------------------------
export enum Importance {
  Low = 'low',
  Normal = 'normal',
  High = 'high'
}

export enum FilterMethod {
  All = 'all',
  Unread = 'unread',
  Flagged = 'flagged',
  Search = 'search',
  Tagged = 'tagged'
}

export enum SendMethod {
  New = 'new',
  Reply = 'reply',
  ReplyAll = 'replyAll',
  Forward = 'forward'
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc'
}
