import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { supabase } from 'src/supabase'
import { useUserStore } from 'src/stores/user'
import { EmailNotification } from 'src/types'
import { useEmailStore } from 'src/stores/email'

export const useEmailNotificationsStore = defineStore('emailNotifications', () => {
  const user = useUserStore()
  const emailStore = useEmailStore()

  // State
  const notifications: Ref<EmailNotification[]> = ref([])
  const listening = ref(false)

  // Functions
  function clear() { notifications.value = [] }
  function listen(cb: (payload: EmailNotification) => void) {
    if (listening.value) return
    if (!user.activeTenant) return
    supabase.channel('email_notifications_channel').on('postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'email_notifications',
        filter: `user_id=eq.${user.id}`
      },
      (payload) => {
        const newNotification = payload as unknown as EmailNotification
        notifications.value.push(newNotification)
        cb(newNotification)
        emailStore.handleIncomingNotification()
      }
    ).subscribe()
    listening.value = true
  }

  return {
    notifications,
    clear,
    listening,
    listen
  }
})
