import { computed, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import type { Tenant, Channel, Employee, Department, Organization, Location, Industry, Brand, ProposalType, OppOOM, Approver, Phase, StorageFolder, Holiday, Role, ProjectResourceStatus, JobTitle } from 'src/types'
import * as api from 'src/api'
import { useSettingsStore } from 'src/stores/settings'
import { useUserStore } from './user'
import { supabase } from 'src/supabase'

export const useMainStore = defineStore('main', () => {
  const isLoaded = ref(false)
  const isDevServer: boolean = import.meta.env.VITE_SUPABASE_URL === 'https://vymdeuqxzmnuunlamdnu.supabase.co'
  const tenantLogo = ref<string | null>(null)
  const jobTitles: Ref<JobTitle[]> = ref([])
  const locations: Ref<Location[]> = ref([])
  const departments: Ref<Department[]> = ref([])
  const externalDepartments: Ref<Department[]> = ref([])
  const industries: Ref<Industry[]> = ref([])
  const brands: Ref<Brand[]> = ref([])
  const organization: Ref<Organization | null> = ref(null)
  const proposalTypes: Ref<ProposalType[]> = ref([])
  const oppOrdersOfMagnitude: Ref<OppOOM[]> = ref([])
  const approvers: Ref<Approver[]> = ref([])
  const phases: Ref<Phase[]> = ref([])
  const channels: Ref<Channel[]> = ref([])
  const storageFolders: Ref<StorageFolder[]> = ref([])
  const holidays: Ref<Holiday[]> = ref([])
  const roles: Ref<Role[]> = ref([])
  const tenants: Ref<Tenant[]> = ref([])
  const resourceStatuses: Ref<ProjectResourceStatus[]> = ref([])
  const tenantLogos = ref<{ tenant_id: string, url: string }[]>([])
  const color: Ref<string> = ref('#000000')

  const allEmployees: Ref<Employee[]> = ref([]) // active and inactive employees
  const employees = computed<Employee[]>(() => allEmployees.value.filter((emp) => emp.is_deleted === false)) // only active employees
  const employeesInTenant: Ref<Employee[]> = computed<Employee[]>(() => {
    return employees.value.filter((emp) => emp.tenant_id === useUserStore().activeTenantId)
  })

  type PromiseObj = {
    promise: Promise<{ data: unknown[], error?: string } | { data?: undefined, error: string } | { data: unknown, error?: undefined }>;
    for?: { value: unknown[] | unknown };
  }

  function clearAppData() {
    isLoaded.value = false
    tenantLogo.value = null
    allEmployees.value = []
    jobTitles.value = []
    locations.value = []
    departments.value = []
    externalDepartments.value = []
    industries.value = []
    brands.value = []
    organization.value = null
    proposalTypes.value = []
    oppOrdersOfMagnitude.value = []
    approvers.value = []
    phases.value = []
    channels.value = []
    storageFolders.value = []
    holidays.value = []
    roles.value = []
    resourceStatuses.value = []
    color.value = '#000000'
  }

  async function refreshAppData() {
    clearAppData()
    const promiseArray: PromiseObj[] = []
    const settingsStore = useSettingsStore()
    const user = useUserStore()
    promiseArray.push({ promise: settingsStore.getSettings() })
    if (allEmployees.value.length === 0) promiseArray.push({ promise: api.employees.getAll(), for: allEmployees })
    // if (crossTenantEmployees.value.length === 0) promiseArray.push({ promise: api.crossTenantEmployees.getAll(), for: crossTenantEmployees })
    if (departments.value.length === 0) promiseArray.push({ promise: api.departments.getAll({ sortBy: 'name' }), for: departments })
    if (locations.value.length === 0) promiseArray.push({ promise: api.locations.getAll(), for: locations })
    if (industries.value.length === 0) promiseArray.push({ promise: api.industries.getAll(), for: industries })
    if (brands.value.length === 0) promiseArray.push({ promise: api.brands.getAll(), for: brands })
    if (proposalTypes.value.length === 0) promiseArray.push({ promise: api.proposalTypes.getAll(), for: proposalTypes })
    if (approvers.value.length === 0) promiseArray.push({ promise: api.approvers.getAll(), for: approvers })
    if (phases.value.length === 0) promiseArray.push({ promise: api.phases.getAll(), for: phases })
    if (channels.value.length === 0) promiseArray.push({ promise: api.channels.getAll(), for: channels })
    if (oppOrdersOfMagnitude.value.length === 0) promiseArray.push({ promise: api.oppOOMs.getAll(), for: oppOrdersOfMagnitude })
    if (storageFolders.value.length === 0) promiseArray.push({ promise: api.storageFolders.getAll(), for: storageFolders })
    if (holidays.value.length === 0) promiseArray.push({ promise: api.holidays.getAll(), for: holidays })
    if (roles.value.length === 0) promiseArray.push({ promise: api.roles.getAll(), for: roles })
    if (resourceStatuses.value.length === 0) promiseArray.push({ promise: api.projectResourceStatuses.getAll(), for: resourceStatuses })
    if (tenants.value.length === 0) promiseArray.push({ promise: api.tenants.getAll(), for: tenants })
    if (jobTitles.value.length === 0) promiseArray.push({ promise: api.jobTitles.getAll(), for: jobTitles })
    promiseArray.push({ promise: api.organization.get(1), for: organization })

    await Promise.all(promiseArray.map((prom) => prom.promise)).then((returnValues) => {
      promiseArray.forEach(async (val, index) => {
        const returnValue = returnValues[index]
        if (!returnValue || returnValue.error || !returnValue.data) return
        if (!val.for) return
        if (val.for.value === departments.value) {
          departments.value = (returnValue.data as Department[]).filter((dep) => !dep.is_external)
          externalDepartments.value = (returnValue.data as Department[]).filter((dep) => dep.is_external)
        }
        else val.for.value = returnValue.data as typeof val.for.value
        // If organization, get signed URL for logo
        if (val.for.value === organization.value) {
          if (organization.value && organization.value.logo) {
            if (!user.activeTenantId) return
            const { data: urlData, error: urlError } = await supabase.storage.from(user.activeTenantId).createSignedUrl('organization/' + organization.value.logo, 300) // 5 minutes
            if (!urlError && urlData) {
              tenantLogo.value = urlData.signedUrl
              tenantLogos.value.push({ tenant_id: user.activeTenantId, url: urlData.signedUrl })
            }
          }
          if (organization.value) {
            color.value = organization.value.color || '#000000'
            document.documentElement.style.setProperty('--org-color', color.value)
          }
        }
        // If employees, sort
        if (val.for.value === allEmployees.value) {
          employees.value.sort((a, b) => {
            if (a.profile.last_name < b.profile.last_name) return -1
            if (a.profile.last_name > b.profile.last_name) return 1
            return 0
          })
        }
        // If cross-tenant employees, sort
        // if (val.for.value === crossTenantEmployees.value) {
        //   crossTenantEmployees.value.sort((a, b) => {
        //     if (a.employee.profile.last_name < b.employee.profile.last_name) return -1
        //     if (a.employee.profile.last_name > b.employee.profile.last_name) return 1
        //     return 0
        //   })
        // }
        // If approvers, sort
        if (val.for.value === approvers.value) {
          approvers.value.sort((a, b) => {
            if (a.is_master && !b.is_master) return -1
            if (!a.is_master && b.is_master) return 1
            return 0
          })
        }
        // If roles, remove roles for other tenants
        if (val.for.value === roles.value) {
          roles.value = roles.value.filter((role) => role.tenant_id === user.activeTenantId)
        }
        // If phases, sort
        if (val.for.value === phases.value) {
          phases.value.sort((a, b) => a.order - b.order)
        }
      })
    }).catch((error) => {
      console.error(error)
    })
    isLoaded.value = true
  }

  // --------------------
  // Approvers
  // --------------------
  async function refreshApprovers() {
    const { data, error } = await api.approvers.getAll()
    if (!error && data) {
      // Sort so that master approver is first
      data.sort((a, b) => {
        if (a.is_master && !b.is_master) return -1
        if (!a.is_master && b.is_master) return 1
        return 0
      })
      approvers.value = data as Approver[]
    }
  }

  function getApprover(id: number) {
    return approvers.value.find((approver) => approver.id === id)
  }

  function getApproverAsEmployee(id: number) {
    const approver = getApprover(id)
    if (approver) return employees.value.find((emp) => emp.id === approver.employee_id)
    else return null
  }

  // --------------------
  // Job Titles
  // --------------------
  async function refreshJobTitles() {
    const { data, error } = await api.jobTitles.getAll()
    if (!error) jobTitles.value = data as JobTitle[]
  }

  function getJobTitle(id: number) {
    return jobTitles.value.find((jobTitle) => jobTitle.id === id)
  }

  // --------------------
  // Brands
  // --------------------
  function getBrand(id: number) {
    return brands.value.find((brand) => brand.id === id)
  }
  async function refreshBrands() {
    const { data, error } = await api.brands.getAll()
    if (!error) brands.value = data as Brand[]
  }

  // --------------------
  // Channels
  // --------------------
  async function refreshChannels() {
    const { data, error } = await api.channels.getAll()
    if (!error) channels.value = data as Channel[]
  }

  // --------------------
  // Tenants
  // --------------------
  function getTenant(id: string) {
    return tenants.value.find((tenant) => tenant.id === id)
  }

  // --------------------
  // Departments
  // --------------------
  function getDepartment(id: number) {
    return departments.value.find((dep) => dep.id === id)
  }
  function getExternalDepartment(id: number) {
    return externalDepartments.value.find((dep) => dep.id === id)
  }
  function getDepartmentName(id: number) {
    const dep = getDepartment(id)
    if (dep) return dep.name
    else return null
  }
  function getDepartmentManager(deptId: number): Employee | null {
    const dep = getDepartment(deptId)
    if (dep && dep.manager_id) {
      return employees.value.find((emp) => emp.id === dep.manager_id) || null
    }
    return null
  }
  async function refreshDepartments() {
    const { data, error } = await api.departments.getAll({ sortBy: 'name' })
    if (!error) {
      departments.value = (data as Department[]).filter((dep) => !dep.is_external)
      externalDepartments.value = (data as Department[]).filter((dep) => dep.is_external)
    }
  }

  // --------------------
  // Employees
  // --------------------
  function getEmployee(id: string): Employee | null {
    const found = allEmployees.value.find((emp) => emp.id === id)
    if (found) return found
    return null
  }
  function getEmployeesWithRole(roleSlug: string, onlyInTenant?: boolean): Employee[] {
    const empList = onlyInTenant ? employeesInTenant.value : employees.value
    return empList.filter((emp) => emp.profile.roles.find((role) => role.role_slug === roleSlug))
    // const foundCtes = crossTenantEmployees.value.filter((cte) => cte.employee.profile.roles.find((role) => role.role_slug === roleSlug))
    // return foundEmps.concat(foundCtes.map((cte) => cte.employee))
  }
  function getEmployeeName(id: string) {
    const emp = getEmployee(id)
    // if (!emp) {
    //   const cte = crossTenantEmployees.value.find((cte) => cte.employee.id === id)
    //   if (cte) return cte.employee.profile.name
    //   else return null
    // }
    if (emp) return emp.profile.name
    else return null
  }
  function getEmployeeInitials(id: string) {
    const emp = getEmployee(id)
    // if (!emp) {
    //   const cte = crossTenantEmployees.value.find((cte) => cte.employee.id === id)
    //   if (cte) return cte.employee.profile.first_name[0] + cte.employee.profile.last_name[0]
    //   else return null
    // }
    if (emp) return emp.profile.first_name[0] + emp.profile.last_name[0]
    else return null
  }
  async function refreshEmployees() {
    const { data, error } = await api.employees.getAll()
    if (!error) {
      data?.sort((a, b) => {
        if (a.profile.last_name < b.profile.last_name) return -1
        if (a.profile.last_name > b.profile.last_name) return 1
        return 0
      })
      allEmployees.value = (data as Employee[])
    }
    // const { data: crossData, error: crossError } = await api.crossTenantEmployees.getAll()
    // if (!crossError) {
    //   crossData?.sort((a, b) => {
    //     if (a.employee.profile.last_name < b.employee.profile.last_name) return -1
    //     if (a.employee.profile.last_name > b.employee.profile.last_name) return 1
    //     return 0
    //   })
    //   crossTenantEmployees.value = (crossData as CrossTenantEmployee[])
    // }
  }

  // --------------------
  // Holidays
  // --------------------
  async function refreshHolidays() {
    const { data, error } = await api.holidays.getAll()
    if (!error) holidays.value = data as Holiday[]
  }

  // --------------------
  // Industries
  // --------------------
  function getIndustry(code: number) {
    return industries.value.find((ind) => ind.code === code)
  }

  async function refreshIndustries() {
    const { data, error } = await api.industries.getAll()
    if (!error) industries.value = data as Industry[]
  }

  // --------------------
  // Locations
  // --------------------
  function getLocation(id: number): Location | undefined {
    return locations.value.find((loc) => loc.id === id)
  }
  async function refreshLocations() {
    const { data, error } = await api.locations.getAll()
    if (!error) locations.value = data as Location[]
  }

  // --------------------
  // Opp Orders of Magnitude
  // --------------------
  async function refreshOOMs() {
    const { data, error } = await api.oppOOMs.getAll()
    if (!error) oppOrdersOfMagnitude.value = data as OppOOM[]
  }
  function getOOM(oppOrderOfMagnitudeId: number): OppOOM | null {
    for (let i = 0; i < oppOrdersOfMagnitude.value.length; i++) {
      if (oppOrdersOfMagnitude.value[i].id === oppOrderOfMagnitudeId) return oppOrdersOfMagnitude.value[i]
    }
    return null
  }

  // --------------------
  // Organization
  // --------------------
  async function refreshOrganization() {
    const { data, error } = await api.organization.get(1)
    if (!error) {
      organization.value = data as Organization
      if (organization.value.logo) {
        const user = useUserStore()
        if (!user.activeTenantId) return
        const { data: urlData, error: urlError } = await supabase.storage.from(user.activeTenantId).createSignedUrl('organization/' + organization.value.logo, 300) // 5 minutes
        if (!urlError && urlData) {
          tenantLogo.value = urlData.signedUrl
          tenantLogos.value.push({ tenant_id: user.activeTenantId, url: urlData.signedUrl })
        }
      }
      color.value = organization.value.color || '#000000'
      document.documentElement.style.setProperty('--org-color', color.value)
    }
  }

  // --------------------
  // Phases
  // --------------------
  function getPhase(id: number) {
    return phases.value.find((phase) => phase.id === id)
  }
  function getPhaseByName(name: string) {
    return phases.value.find((phase) => phase.name === name)
  }

  function getPhaseName(id: number) {
    const phase = getPhase(id)
    if (phase) return phase.name
    else return null
  }

  function getPhaseAbbr(phaseName: string) {
    const phase = phases.value.find((ph) => ph.name === phaseName)
    if (phase) return phase.abbr
    else return null
  }
  function getPhaseColor(phaseName: string) {
    const phase = phases.value.find((ph) => ph.name === phaseName)
    if (phase) return phase.color
    else return null
  }

  // --------------------
  // Proposal Types
  // --------------------
  function getProposalType(id: number) {
    const found = proposalTypes.value.find((type) => type.id === id)
    if (found) return found.name
    else return null
  }
  async function refreshProposalTypes() {
    const { data, error } = await api.proposalTypes.getAll()
    if (!error) proposalTypes.value = data as ProposalType[]
  }

  // --------------------
  // Resource Statuses
  // --------------------
  function refreshResourceStatuses() {
    api.projectResourceStatuses.getAll().then((result) => {
      if (!result.error) resourceStatuses.value = result.data as ProjectResourceStatus[]
    })
  }

  // --------------------
  // Roles
  // --------------------
  async function refreshRoles() {
    const { data, error } = await api.roles.getAll()
    if (!error) roles.value = data as Role[]
  }
  function getRoleSlug(roleName: string) {
    const found = roles.value.find((role) => role.name === roleName)
    if (found) return found.role_slug
    else return null
  }

  // --------------------
  // Storage Folders
  // --------------------
  function refreshStorageFolders() {
    api.storageFolders.getAll().then((result) => {
      if (!result.error) storageFolders.value = result.data as StorageFolder[]
    })
  }

  return {
    approvers,
    brands,
    channels,
    // crossTenantEmployees,
    departments,
    externalDepartments,
    employees,
    holidays,
    industries,
    locations,
    oppOrdersOfMagnitude,
    organization,
    phases,
    proposalTypes,
    storageFolders,
    roles,
    resourceStatuses,
    tenants,
    tenantLogo,
    color,
    employeesInTenant,
    isLoaded,
    isDevServer,
    jobTitles,

    getApprover,
    getApproverAsEmployee,
    getBrand,
    getDepartment,
    getExternalDepartment,
    getDepartmentName,
    getDepartmentManager,
    getEmployee,
    getEmployeeName,
    getEmployeeInitials,
    getIndustry,
    getLocation,
    getOOM,
    getPhase,
    getPhaseByName,
    getPhaseColor,
    getPhaseName,
    getPhaseAbbr,
    getProposalType,
    getEmployeesWithRole,
    getRoleSlug,
    getJobTitle,
    getTenant,

    refreshAppData,
    refreshApprovers,
    refreshBrands,
    refreshChannels,
    refreshDepartments,
    refreshHolidays,
    refreshIndustries,
    refreshLocations,
    refreshOrganization,
    refreshProposalTypes,
    refreshOOMs,
    refreshEmployees,
    refreshStorageFolders,
    refreshRoles,
    refreshResourceStatuses,
    refreshJobTitles
  }
})
